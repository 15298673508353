import * as types from "../types";

export const fetchFuelRequest = ({ requestData }) => ({
  type: types.FETCH_FUEL_REQUEST,
  payload: {
    requestData,
  },
});

export const fetchFuelSuccess = ({ fuel }) => ({
  type: types.FETCH_FUEL_SUCCESS,
  payload: {
    fuel,
  },
});

export const fetchFuelFailure = ({ error }) => ({
  type: types.FETCH_FUEL_FAILURE,
  payload: {
    error: error.message,
  },
});
