import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const Ticket = ({ ticket }) => {
  const [ticketdata, setTicketData] = useState([]);

  useEffect(() => {
    let ticketData = [];
    if (ticket) {
      let device_fk = Object.values(ticket.device_fk_id);
      let ticket_id = Object.values(ticket.ticket_id);
      let created_at = Object.values(ticket.created_at);
      let ticket_status = Object.values(ticket.ticket_status);
      let issue_name = Object.values(ticket.issue_name);
      let support_comments = Object.values(ticket.support_comments);
      let ticket_summary = Object.values(ticket.ticket_summary);
      for (let i = 0; i < created_at.length; i++) {
        var milliSeconds = Date.parse(created_at[i]);
        const dateObject = new Date(milliSeconds);
        const humanDateFormat = dateObject.toLocaleString();
        ticketData.push({
          deviceFk: device_fk[i],
          ticketId: ticket_id[i],
          createdAt: humanDateFormat,
          ticketStatus: ticket_status[i],
          issueName: issue_name[i],
          supportComments: support_comments[i],
          ticketSummary: ticket_summary[i],
        });
      }
      setTicketData(ticketData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  return (
    <div style={{ height: "250px", overflowY: "scroll" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Device Fk Id</TableCell>
              <TableCell>Ticket Id</TableCell>
              <TableCell>Created At (IST)</TableCell>
              <TableCell>Ticket Status</TableCell>
              <TableCell>Issue Name</TableCell>
              <TableCell>Supporting Comments</TableCell>
              <TableCell>Ticket Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketdata.map((row) => (
              <TableRow key={row.ticketId}>
                <TableCell component="th" scope="row">
                  {row.deviceFk}
                </TableCell>
                <TableCell>{row.ticketId}</TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.ticketStatus}</TableCell>
                <TableCell>{row.issueName}</TableCell>
                <TableCell>{row.supportComments}</TableCell>
                <TableCell>{row.ticketSummary}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticketReducer.ticket,
  ticketLoading: state.ticketReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
