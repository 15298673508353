import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchFuelModelFailure,
  fetchFuelModelSuccess,
} from "../actions/fetchFuelModelAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchFuelModelSaga(action) {
  try {
    yield put(clearErrors());
    let fuelModel = yield call(_api.deviceFuelModel, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(fetchFuelModelSuccess({ fuelModel }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchFuelModelFailure({ error: e }));
  }
}
export default function* fetchFuelModelSaga() {
  yield takeLatest(types.FETCH_FUEL_MODEL_REQUEST, asyncFetchFuelModelSaga);
}
