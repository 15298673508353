import * as types from "../types";

export const fuelCloseTicketRequest = ({ requestCloseFuelTicket }) => ({
  type: types.FUEL_TICKET_REQUEST,
  payload: {
    requestCloseFuelTicket,
  },
});

export const fuelCloseTicketSuccess = ({ resCloseFuelTicket }) => ({
  type: types.FUEL_TICKET_SUCCESS,
  payload: {
    resCloseFuelTicket,
  },
});

export const fuelCloseTicketFailure = ({ error }) => ({
  type: types.FUEL_TICKET_FAILURE,
  payload: {
    error: error.message,
  },
});

export const fuelCloseTicketReset = () => ({
  type: types.FUEL_TICKET_RESET,
});
