import * as types from "../types";

export const fetchFuelSummaryRequest = ({ requestDevice }) => ({
  type: types.FETCH_FUEL_SUMMARY_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchFuelSummarySuccess = ({ fuelSummary }) => ({
  type: types.FETCH_FUEL_SUMMARY_SUCCESS,
  payload: {
    fuelSummary,
  },
});

export const fetchFuelSummaryFailure = ({ error }) => ({
  type: types.FETCH_FUEL_SUMMARY_FAILURE,
  payload: {
    error: error.message,
  },
});
