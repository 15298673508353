import { takeLatest, call, put } from "redux-saga/effects";
import { fuelCloseTicketSuccess, fuelCloseTicketFailure } from "../actions/fuelTicketAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFuelCloseTicketSaga(action) {
  try {
    yield put(clearErrors());
    let resCloseFuelTicket = yield call(_api.closeFuelTicket, {
      requestCloseFuelTicket: action.payload.requestCloseFuelTicket,
    });
    yield put(fuelCloseTicketSuccess({ resCloseFuelTicket }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fuelCloseTicketFailure({ error: e }));
  }
}
export default function* fuelCloseTicketSaga() {
  yield takeLatest(types.FUEL_TICKET_REQUEST, asyncFuelCloseTicketSaga);
}
