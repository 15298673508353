import * as types from "../types";

export const fetchThresholdRequest = ({ requestDevice }) => ({
  type: types.FETCH_THRESHOLD_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchThresholdSuccess = ({ thresholdValue }) => ({
  type: types.FETCH_THRESHOLD_SUCCESS,
  payload: {
    thresholdValue,
  },
});

export const fetchThresholdFailure = ({ error }) => ({
  type: types.FETCH_THRESHOLD_FAILURE,
  payload: {
    error: error.message,
  },
});
