import { takeLatest, call, put } from "redux-saga/effects";
import { replacementDetailFailure, replacementDetailSuccess } from "../actions/replacemetDetailAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncReplacementDetailSaga(action) {
  try {
    yield put(clearErrors());
    let replacementDetail = yield call(_api.getReplacementDetail, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(replacementDetailSuccess({ replacementDetail }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(replacementDetailFailure({ error: e }));
  }
}
export default function* replacementDetailSaga() {
  yield takeLatest(types.REPLACEMENT_DATA_REQUEST, asyncReplacementDetailSaga);
}
