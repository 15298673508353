import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchFirebaseDataFailure,
  fetchFirebaseDataSuccess,
} from "../actions/fetchFirebaseDataAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchFirebaseDataSaga(action) {
  try {
    yield put(clearErrors());
    let firebaseData = yield call(_api.firebaseData, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(fetchFirebaseDataSuccess({ firebaseData }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchFirebaseDataFailure({ error: e }));
  }
}
export default function* fetchFirebaseDataSaga() {
  yield takeLatest(types.FETCH_FIREBASE_REQUEST, asyncFetchFirebaseDataSaga);
}
