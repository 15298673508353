export const getTime=(time)=>{
    const date = new Date(time);

    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    let time12hrFormat = date.toLocaleTimeString('en-US', options);
    const [hour, minute] = time12hrFormat.split(/[:\s]/);

    const formattedHour = hour.padStart(2, '0');

    time12hrFormat = `${formattedHour}:${minute}`;
    return time12hrFormat
}