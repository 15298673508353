import * as types from "../types";

const initialState = {
  loading: false,
  resFuelTicket: null,
  requestTicket: null,
  error: null,
};

export default function fetchFuelTicketReducer(state = initialState, action) {
  switch (action.type) {
    case types.FUEL_TICKET_METADATA_REQUEST:
      return {
        ...state,
        loading: true,
        requestTicket: action.payload.requestTicket,
      };

    case types.FUEL_TICKET_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        resFuelTicket: action.payload.resFuelTicket,
      };

    case types.FUEL_TICKET_METADATA_FAILURE:
      return {
        ...state,
        loading: false,
        resFuelTicket: null,
        requestTicket: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
