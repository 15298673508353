import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { tripReplayStyles } from "./tripReplay.styles";
import GoogleMapReact from "google-map-react";
import markerIcon from "../../assets/home/TractorIcon2.svg";
import getTractor from "../../utils/getTractor";
// import { getTimeDiff } from "../../utils";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import CircularProgress from "@material-ui/core/CircularProgress";

let plottedMarkers = null;
const GoogleMaps = ({
  fueldata,
  fuelLoading,
  dataDaySummary,
  mapPlotLoading,
  summary,
  commutes,
  patches,
  replay,
  dailyStatus,
  area,
  totalTime,
  kms,
}) => {
  const classes = tripReplayStyles();

  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMapInstance] = useState(null);
  const [maps, setMapsInstance] = useState(null);
  const [allPolygons, setPolygons] = useState(null);
  const [allPolylines, setPolyLines] = useState(null);

  const [gameLoop, setGameLoop] = useState(null);
  const [block, setBlock] = useState(true);
  const [time, setTime] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [engineStatus, setEngineStatus] = useState(false);
  const [speed, setSpeed] = useState(500);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (fueldata && dailyStatus && mapLoaded) {
      clearPolyLinesIfAny();
      plotFinalPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fueldata, dailyStatus]);

  const onMapLoaded = (map, maps) => {
    map.setMapTypeId(maps.MapTypeId.HYBRID);
    setMapMarkers(map, maps);
  };

  let index = useRef(1);
  let polylines = [];
  let polygons = [];
  let bounds;

  let defaultProps = {
    center: {
      lat: 20.5937,
      lng: 78.9629,
    },
    zoom: 19,
  };

  const clearPolyLinesIfAny = () => {
    if (allPolylines !== null && allPolylines.length > 0) {
      for (let i = 0; i < allPolylines.length; i++) {
        allPolylines[i].setMap(null);
      }
      setPolyLines(null);
    }

    if (allPolygons !== null && allPolygons.length > 0) {
      for (let i = 0; i < allPolygons.length; i++) {
        allPolygons[i].setMap(null);
      }
      setPolygons(null);
    }
  };

  const clearMarkersIfAny = () => {
    if (plottedMarkers && plottedMarkers.length > 0) {
      for (let i = 0; i < plottedMarkers.length; i++) {
        plottedMarkers[i].setMap(null);
      }
    }
  };

  const plotFinalPoints = async () => {
    bounds = new maps.LatLngBounds();
    const allCommuteLats = commutes.lats;
    const allCommuteLngs = commutes.lngs;
    drawPoints(allCommuteLats, allCommuteLngs, false);

    const allPatchLats = patches.lats;
    const allPatchLngs = patches.lngs;
    drawPoints(allPatchLats, allPatchLngs, true);
    map.fitBounds(bounds);
  };

  function drawPoints(allLats, allLngs, isPatch) {
    for (let i = 0; i < allLats.length; i++) {
      const points = [];
      for (let j = 0; j < allLats[i].length; j++) {
        let tractorPoint = new maps.LatLng(allLats[i][j], allLngs[i][j]);
        let latLng = { lat: allLats[i][j], lng: allLngs[i][j] };
        points.push(latLng);
        bounds.extend(tractorPoint);
      }

      if (isPatch) {
        const tractorPatch = new maps.Polygon({
          paths: points,
          strokeColor: "#2196f3",
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: "#1565c0",
          fillOpacity: 1,
        });
        tractorPatch.setMap(map);
        polygons.push(tractorPatch);
      } else {
        const tractorPath = new maps.Polyline({
          path: points,
          geodesic: true,
          strokeColor: "#BE1E2D",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        });

        tractorPath.setMap(map);
        polylines.push(tractorPath);
      }
    }

    setPolyLines(polylines);
    setPolygons(polygons);
  }

  const setMapMarkers = (map, maps) => {
    clearMarkersIfAny();
    let bounds = new maps.LatLngBounds();
    plottedMarkers = [];

    if (replay) {
      let devicePostion = new maps.LatLng(
        commutes.lats[0][0],
        commutes.lngs[0][0]
      );

      let marker = new maps.Marker({
        position: {
          lat: commutes.lats[0][0],
          lng: commutes.lngs[0][0],
        },
        map,
        icon: markerIcon,
        optimized: false,
      });

      marker.setMap(map);
      plottedMarkers.push(marker);

      bounds.extend(devicePostion);
    }
  };

  function move(lat, lng, map, maps, timer, nextlat, nextlng, status) {
    let bounds = new maps.LatLngBounds();
    let tractorPosition = new maps.LatLng(parseFloat(lat), parseFloat(lng));
    const point1LatLng = new maps.LatLng(lat, lng);
    const point2LatLng = new maps.LatLng(nextlat, nextlng);

    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );

    if (status > 0) setEngineStatus(true);
    else setEngineStatus(false);

    const actualAngle = angle - 180;

    if (timer !== undefined) {
      const dateObject = new Date(timer - 19800000);
      const Time = dateObject.toLocaleTimeString("en-US");
      setTime(Time);
    } else {
      setTime("TimeStamp");
    }

    clearMarkersIfAny();

    let Value = getTractor(actualAngle);

    const image = {
      url: Value,
      scaledSize: new maps.Size(45.35, 39.5),
      origin: new maps.Point(0, 0),
      anchor: new maps.Point(25, 25),
    };
    let marker = new maps.Marker({
      position: {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
      map: map,
      icon: image,

      optimized: false,
    });

    marker.setMap(map);
    plottedMarkers.push(marker);
    bounds.extend(tractorPosition);
  }

  let clear;

  const play = (map, maps, velocity) => {
    let timer = replay.ts;
    let lat = replay.lats;
    let lng = replay.lngs;
    let status = replay.status;
    setBlock(false);

    setShowTime(true);
    clearMarkersIfAny();
    clear = setInterval(() => {
      setGameLoop(clear);
      if (index.current >= lat.length) {
        index.current = 1;
        clearInterval(clear);
      }
      move(
        lat[index.current],
        lng[index.current],
        map,
        maps,
        timer[index.current],
        lat[index.current - 1],
        lng[index.current - 1],
        status[index.current - 1]
      );
      index.current++;
    }, velocity);
  };

  const pauseGame = (map, maps) => {
    clearInterval(gameLoop);
  };

  const stop = (map, maps) => {
    let lat = commutes.lats[0][0];
    let lng = commutes.lngs[0][0];
    clearInterval(gameLoop);
    // setBlock(true);
    setGameLoop(null);
    setToggle(false);
    clearMarkersIfAny();
    move(lat[0], lng[0], map, maps);
    index.current = 1;
  };

  const handleChange = (event) => {
    clearInterval(gameLoop);
    const val = event.target.value;
    setSpeed(val);
    play(map, maps, val);
  };

  const handlePlayPause = () => {
    if (toggle === false) {
      setToggle(true);
      setSpeed(500);
      play(map, maps, 500);
    } else {
      setToggle(false);
      pauseGame(map, maps);
    }
  };

  return (
    <div className={classes.tilesWrapper}>
      <div className={classes.bigTile}>
        <GoogleMapReact
          style={{
            position: "absolute",
            width: "80%",
            height: "50%",
            overflowY: "auto",
            outline: "none",
            margin: "1em",
          }}
          bootstrapURLKeys={{
            key: "AIzaSyBH4QT3haAP0nvHdK2ALRETv8trg - my1No",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            onMapLoaded(map, maps);
            setMapsInstance(maps);
            setMapInstance(map);
            setMapLoaded(true);
          }}
        >
          {mapLoaded && dailyStatus && setMapMarkers(map, maps)}
        </GoogleMapReact>
        {fuelLoading ? (
          <div style={{ margin: "13em" }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {" "}
            {dailyStatus && (
              <div
                style={{
                  position: "absolute",
                  margin: "30em",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                  justifyContent: "center",
                }}
              >
                <div>
                  <button
                    className={classes.controlButton}
                    onClick={handlePlayPause}
                  >
                    {!toggle ? (
                      <>
                        Play <PlayArrowIcon />
                      </>
                    ) : (
                      <>
                        Pause <PauseIcon />
                      </>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    className={classes.controlButton}
                    width="100%"
                    onClick={() => stop(map, maps)}
                  >
                    Stop
                    <StopIcon />
                  </button>
                </div>
                <div>
                  <Select
                    id="demo-simple-select-outlined"
                    defaultValue={500}
                    value={speed}
                    onChange={handleChange}
                    className={classes.speedButton}
                  >
                    <MenuItem value={500}>1x</MenuItem>
                    <MenuItem value={300}>2x</MenuItem>
                    <MenuItem value={100}>3x</MenuItem>
                  </Select>
                </div>
                <div className={classes.sliderButton}>
                  <Slider
                    value={index.current ?? 0}
                    getAriaValueText={() => "hello"}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    color="secondary"
                    step={1}
                    marks
                    min={0}
                    max={Object.keys(replay.lats).length ?? 0}
                    onChange={(e, number) => {
                      index.current = number;
                    }}
                  />
                </div>
                {showTime && (
                  <div className={classes.timeButton}>
                    <h4>{showTime && !block && time}</h4>
                    <h4>Status</h4>
                    {engineStatus === true ? (
                      <button
                        style={{
                          backgroundColor: "green",
                          borderRadius: "45%",
                          height: "30px",
                          width: "30px",
                        }}
                      ></button>
                    ) : (
                      <button
                        style={{
                          backgroundColor: "red",
                          borderRadius: "45%",
                          height: "30px",
                          width: "30px",
                        }}
                      ></button>
                    )}
                  </div>
                )}
                <div className={classes.summaryButton}>
                  <h4>Kilo Meters :{kms}</h4>
                  <h4>Hours Used :{totalTime}</h4>
                  <h4>Acres : {area}</h4>
                  {/* <h4>
                    Last Active : {getTimeDiff(summary.last_ping_time[0])}
                  </h4> */}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fueldata: state.fuelReducer.fuel,
  fuelLoading: state.fuelReducer.loading,
  dataDaySummary: state.daySummData.daySummary,
  summary: state.summaryReducer.summary,
  area: state.daySummData.area,
  totalTime: state.daySummData.totalTime,
  kms: state.daySummData.kms,
  mapPlotLoading: state.daySummData.loading,
  commutes: state.daySummData.commutes,
  patches: state.daySummData.patches,
  dailyStatus: state.daySummData.dailyStatus,
  replay: state.daySummData.replay,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMaps);
