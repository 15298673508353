import * as types from "../types";

export const fetchInstAttemptsRequest = ({ requestDevice }) => ({
  type: types.FETCH_INST_ATTEMPTS_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchInstAttemptsSuccess = ({ instAttempts }) => ({
  type: types.FETCH_INST_ATTEMPTS_SUCCESS,
  payload: {
    instAttempts,
  },
});

export const fetchInstAttemptsFailure = ({ error }) => ({
  type: types.FETCH_INST_ATTEMPTS_FAILURE,
  payload: {
    error: error.message,
  },
});
