import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TableComponent } from "../../elements";

const Alternator = ({ instImageData }) => {
  const [alternatorData, setAlternatorData] = useState([]);

  useEffect(() => {
    let alternator = [];
    if (instImageData) {
      let device_id = Object.values(instImageData.alternator.Device_ID);
      let actual_image = Object.values(instImageData.alternator.Actual_Image);
      let reference_1 = Object.values(
        instImageData.alternator.Reference_Image_1
      );
      let reference_2 = Object.values(
        instImageData.alternator.Reference_Image_2
      );
      let reference_3 = Object.values(
        instImageData.alternator.Reference_Image_3
      );
      for (let i = 0; i < device_id.length; i++) {
        alternator.push({
          device: device_id[i],
          actual: actual_image[i],
          reference1: reference_1[i],
          reference2: reference_2[i],
          reference3: reference_3[i],
        });
      }
      setAlternatorData(alternator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instImageData]);

  return <TableComponent values={alternatorData} />;
};

const mapStateToProps = (state) => ({
  instImageData: state.instImageReducer.instImage,
  instImageLoading: state.instImageReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Alternator);
