import * as types from "../types";

const initialState = {
  loading: false,
  requestCloseFuelTicket: null,
  resCloseFuelTicket: null,
  error: null,
};

export default function fuelCloseTicketReducer(state = initialState, action) {
  switch (action.type) {
    case types.FUEL_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        requestCloseFuelTicket: action.payload.requestCloseFuelTicket,
      };

    case types.FUEL_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        resCloseFuelTicket: action.payload.resCloseFuelTicket,
      };

    case types.FUEL_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        requestCloseFuelTicket: null,
        resCloseFuelTicket: null,
        error: action.payload.error,
      };

    case types.FUEL_TICKET_RESET:
      return {
        ...state,
        loading: false,
        requestCloseFuelTicket: null,
        resCloseFuelTicket: null,
        error: null,
      };

    default:
      return state;
  }
}
