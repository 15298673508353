import { makeStyles } from "@material-ui/core/styles";

export const dashboardStyles = makeStyles((theme) => ({
  inputEvent:{
    background: "#651fff",
    height: "45px",
    width: "200px",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: "0 0.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fuelButton:{
    height: "45px",
    width: "200px",
    borderRadius: "4px",
    color: "#000000",
    padding: "0 0.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:"pointer",
    textTransform:"uppercase",
    fontSize:"1.5em",
    border:"none",
    outline:"none"
  },
  acceptButton:{
    background:"#651fff",
    color:"#fff"
  }
}));
