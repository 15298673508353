import * as types from "../types";

export const fetchInstImageRequest = ({ requestDevice }) => ({
  type: types.FETCH_INST_IMG_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchInstImageSuccess = ({ instImage }) => ({
  type: types.FETCH_INST_IMG_SUCCESS,
  payload: {
    instImage,
  },
});

export const fetchInstImageFailure = ({ error }) => ({
  type: types.FETCH_INST_IMG_FAILURE,
  payload: {
    error: error.message,
  },
});
