import deg0 from "../assets/tractor/0-deg.svg";
import deg45 from "../assets/tractor/45-deg.svg";
import deg90 from "../assets/tractor/90-deg.svg";
import deg135 from "../assets/tractor/135-deg.svg";
import deg180 from "../assets/tractor/180-deg.svg";
import deg225 from "../assets/tractor/225-deg.svg";
import deg270 from "../assets/tractor/270deg.svg";
import deg315 from "../assets/tractor/315-deg.svg";

function getTractor(angle) {
  // console.log("function", angle);
  let value = -angle;
  // console.log("value", value);

  if (-45 < angle && angle < 0) {
    if (Math.abs(45 - value) < Math.abs(0 - value)) {
      return deg315;
    } else {
      return deg0; //neww0
    }
  } else if (-90 < angle && angle < -45) {
    if (Math.abs(90 - value) < Math.abs(45 - value)) {
      return deg270; //neww90
    } else {
      return deg315;
    }
  } else if (-135 < angle && angle < -90) {
    if (Math.abs(135 - value) < Math.abs(90 - value)) {
      return deg225;
    } else {
      return deg270; //neww90
    }
  } else if (-180 < angle && angle < -135) {
    if (Math.abs(180 - value) < Math.abs(135 - value)) {
      return deg180; //neww180
    } else {
      return deg225;
    }
  } else if (-225 < angle && angle < -180) {
    if (Math.abs(225 - value) < Math.abs(180 - value)) {
      return deg135;
    } else {
      return deg180; //neww180
    }
  } else if (-270 < angle && angle < -225) {
    if (Math.abs(270 - value) < Math.abs(225 - value)) {
      return deg90; //neww270
    } else {
      return deg135;
    }
  } else if (-315 < angle && angle < -270) {
    if (Math.abs(315 - value) < Math.abs(270 - value)) {
      return deg45;
    } else {
      return deg90; //neww270
    }
  } else {
    if (Math.abs(360 - value) < Math.abs(315 - value)) {
      return deg0; //neww0
    } else {
      return deg45;
    }
  }
}

export default getTractor;
