import * as types from "../types";

export const replacementDetailRequest = ({ requestDevice }) => ({
  type: types.REPLACEMENT_DATA_REQUEST,
  payload: {
    requestDevice,
  },
});

export const replacementDetailSuccess = ({ replacementDetail }) => ({
  type: types.REPLACEMENT_DATA_SUCCESS,
  payload: {
    replacementDetail,
  },
});

export const replacementDetailFailure = ({ error }) => ({
  type: types.REPLACEMENT_DATA_FAILURE,
  payload: {
    error: error.message,
  },
});
