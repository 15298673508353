import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchRealtimeFailure,
  fetchRealtimeSuccess,
} from "../actions/fetchRealtimeAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchRealtimeSaga(action) {
  try {
    yield put(clearErrors());
    let realtime = yield call(_api.realtime, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("realtime ", realtime);
    yield put(fetchRealtimeSuccess({ realtime }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchRealtimeFailure({ error: e }));
  }
}
export default function* fetchRealtimeSaga() {
  yield takeLatest(types.FETCH_REALTIME_REQUEST, asyncFetchRealtimeSaga);
}
