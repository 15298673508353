import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchInstImageFailure,
  fetchInstImageSuccess,
} from "../actions/fetchInstImageAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchInstImageSaga(action) {
  try {
    yield put(clearErrors());
    let instImage = yield call(_api.deviceInstImg, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(fetchInstImageSuccess({ instImage }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchInstImageFailure({ error: e }));
  }
}
export default function* fetchInstImageSaga() {
  yield takeLatest(types.FETCH_INST_IMG_REQUEST, asyncFetchInstImageSaga);
}
