import * as types from "../types";

export const fetchFuelModelRequest = ({ requestDevice }) => ({
  type: types.FETCH_FUEL_MODEL_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchFuelModelSuccess = ({ fuelModel }) => ({
  type: types.FETCH_FUEL_MODEL_SUCCESS,
  payload: {
    fuelModel,
  },
});

export const fetchFuelModelFailure = ({ error }) => ({
  type: types.FETCH_FUEL_MODEL_FAILURE,
  payload: {
    error: error.message,
  },
});
