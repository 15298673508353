import React, { useEffect, useState } from "react";
import FuelChart from "../../fusionChart/fuelChart";
import SpeedChart from "../../fusionChart/speedChart";
import BatteryVoltage from "../../fusionChart/batteryVoltageChart";
import FuelADC from "../../fusionChart/fuelAdcChart";
import { connect } from "react-redux";
import { fetchFuelRequest } from "../../store/actions/fetchfuelsAction";
import { fetchThresholdRequest } from "../../store/actions/fetchThresholdValueAction";
import { fetchSummaryRequest } from "../../store/actions/fetchSummaryAction";
import { fetchFuelTicketRequest } from "../../store/actions/fetchfuelTicketAction";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams, useLocation } from 'react-router-dom';
import { dashboardStyles } from "./dashboard.styles";
import { fuelCloseTicketRequest, fuelCloseTicketReset } from "../../store/actions/fuelTicketAction";
import CircularProgress from "@material-ui/core/CircularProgress";

const Dashboard = ({ ...props }) => {
  const fuelticketMetadata=props.fuelTicketData?.data;
  const { ticketId,id, date , time } = useParams();
  const [chart, setChart] = useState(1);
  const classes = dashboardStyles();
  const [startTime, setStartTime] = useState(Number(time) || 0);
  const [endTime, setEndTime] = useState(Number(time) + 2 || 24);
  const location = useLocation();
  
  const [eventStartTime,setEventStartTime] = useState("");
  const [eventEndTime,setEventEndTime] = useState("");
  const [fuelEvent,setFuelEvent] = useState("Select Event");
  const [comment,setComment] = useState("");


  useEffect(()=>{
        if(location.pathname.startsWith("/charts")){
          let requestData = {
            device_id: id,
            date: date,
          };
          let requestDevice = {
            device_id: id,
          };
          let requestTicket = {
            id: ticketId,
          };
          props.fuelSubmit({ requestData });
          props.threshold({ requestDevice });
          props.summary({ requestDevice });
          props.fuelTicket({requestTicket})
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname, id, date])

  useEffect(()=>{
    if(props.closeTicketData?.status){
       if(window.confirm(`${props.closeTicketData?.message}`)){
        let requestTicket = {
            id: ticketId,
          };
        props.fuelTicket({requestTicket})
        props.closeTicketReset()
       }
    }else if(props.closeTicketData && !props.closeTicketData?.status){
        if(window.confirm(`${props.closeTicketData?.message}`)){
        let requestTicket = {
            id: ticketId,
          };
        props.fuelTicket({requestTicket})
        props.closeTicketReset()
       }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.closeTicketData])

  const handleChart = (event) => {
    const chartType = event.target.value;
    setChart(chartType);
  };

  const handleStartTime = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTime = (event) => {
    setEndTime(event.target.value);
  };

  function changeChart() {
    if (chart === 2) {
      return <SpeedChart startTime={startTime} endTime={endTime}/>;
    } else if (chart === 3) {
      return <BatteryVoltage startTime={startTime} endTime={endTime}/>;
    } else {
      return <FuelADC startTime={startTime} endTime={endTime}/>;
    }
  }

  const hours = [
  { value: 1, label: "1 AM" },
  { value: 2, label: "2 AM" },
  { value: 3, label: "3 AM" },
  { value: 4, label: "4 AM" },
  { value: 5, label: "5 AM" },
  { value: 6, label: "6 AM" },
  { value: 7, label: "7 AM" },
  { value: 8, label: "8 AM" },
  { value: 9, label: "9 AM" },
  { value: 10, label: "10 AM" },
  { value: 11, label: "11 AM" },
  { value: 12, label: "12 PM" },
  { value: 13, label: "1 PM" },
  { value: 14, label: "2 PM" },
  { value: 15, label: "3 PM" },
  { value: 16, label: "4 PM" },
  { value: 17, label: "5 PM" },
  { value: 18, label: "6 PM" },
  { value: 19, label: "7 PM" },
  { value: 20, label: "8 PM" },
  { value: 21, label: "9 PM" },
  { value: 22, label: "10 PM" },
  { value: 23, label: "11 PM" },
];

  const handleTimeInputChange=(e)=>{
    if(e.target.name === "startEvent"){
      setEventStartTime(e.target.value);
    }else if(e.target.name === "endEvent"){
      setEventEndTime(e.target.value)
    }else if(e.target.name === "fuel-event"){
      setFuelEvent(e.target.value)
    }
  }

  let requestCloseFuelTicket={
        user_id: fuelticketMetadata?.user_id,
        record_id: fuelticketMetadata?.record_id,
        type: "external",
        close_option: true,
        dealer_reassign: false,
        reject_option: false,
        customer_type: "Self",
        customer_index: "Happy",
        need_field_action: false,
        completion_close_by: fuelticketMetadata?.user_id,
    }

  const handleAccept=()=>{
    if(fuelticketMetadata?.event !== "No Fuel Event"){
        requestCloseFuelTicket['tag']=fuelticketMetadata?.tags.approve[0]
        requestCloseFuelTicket['comment']=comment || "Fuel Event Verified"
        props.closeTicket({requestCloseFuelTicket})
    }else{
      if(eventStartTime && eventEndTime && fuelEvent){
        requestCloseFuelTicket['tag']=fuelticketMetadata?.tags.approve[1]
        requestCloseFuelTicket['comment']=comment || "Fuel Event Verified"
        requestCloseFuelTicket['fuel_alerts_data']={
          fuel_event:{
            start_time :eventStartTime,
            end_time:eventEndTime,
            event :fuelEvent
          }
        }
      props.closeTicket({requestCloseFuelTicket})
      }else{
        alert('Plese Select Event Type, Start Time and End Time')
      }
    }
  }

  const handleReject=()=>{
    if(fuelticketMetadata?.event !== "No Fuel Event"){
        requestCloseFuelTicket['tag']=fuelticketMetadata?.tags.reject[0]
        requestCloseFuelTicket['comment']=comment || "Fuel Event Verified"
        props.closeTicket({requestCloseFuelTicket})
    }else{
          requestCloseFuelTicket['tag']=fuelticketMetadata?.tags.reject[1]
          requestCloseFuelTicket['comment']=comment || "Fuel Event Verified"
          props.closeTicket({requestCloseFuelTicket})
      }
    }

  const handleCommentChange=(e)=>{
    setComment(e.target.value);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          gap:"1em",
          padding: "1em",
        }}
      >
        <div style={{display:"flex",flexDirection:"column"}}>
          <label>Select Chart</label>
          <Select
          id="demo-simple-select-outlined"
          defaultValue={1}
          value={chart}
          onChange={handleChart}
          className={classes.inputEvent}
        >
          <MenuItem value={1}>Fuel Chart</MenuItem>
          <MenuItem value={2}>Speed Chart</MenuItem>
          <MenuItem value={3}>Battery Voltage Chart</MenuItem>
          <MenuItem value={4}>Fuel ADC Chart</MenuItem>
        </Select>
        </div>
        <div style={{display:"flex",flexDirection:"column"}}>
          <label>Start Time</label>
        <Select
          id="demo-simple-select-outlined"
          defaultValue={0}
          value={startTime}
          onChange={handleStartTime}
          className={classes.inputEvent}
        >
          {hours.map(hour => (
              <MenuItem key={hour.value} value={hour.value}>
                {hour.label}
              </MenuItem>
            ))}
          <MenuItem value={0}>12 AM</MenuItem>
        </Select>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>End Time</label>
          <Select
            id="demo-simple-select-outlined"
            defaultValue={24}
            value={endTime}
            onChange={handleEndTime}
            className={classes.inputEvent}
          >
            {hours.map(hour => (
              <MenuItem key={hour.value} value={hour.value}>
                {hour.label}
              </MenuItem>
            ))}
            <MenuItem value={24}>12 AM</MenuItem>
          </Select>
        </div>
        {props.fuelTicketData?.data.event === "No Fuel Event" && 
        <>
            <div style={{display:"flex",flexDirection:"column"}}>
              <label>Select Event</label>
                <Select
                id="demo-simple-select-outlined"
                name="fuel-event"
                defaultValue={fuelEvent}
                value={fuelEvent}
                className={classes.inputEvent}
                onChange={handleTimeInputChange}
              >
                <MenuItem value="Select Event">Select Event</MenuItem>
                <MenuItem value="Refuel">Refuel</MenuItem>
                <MenuItem value="Fuel Theft">Fuel Theft</MenuItem>
              </Select>
            </div>
            <div style={{display:"flex",flexDirection:"column"}}>
              <label>Event Start Time</label>
              <input
              className={classes.inputEvent}
              id="timestamp"
              name="startEvent"
              type="datetime-local"
              value={eventStartTime}
              onChange={handleTimeInputChange}
            />
          </div>
          <div style={{display:"flex",flexDirection:"column"}}>
              <label>Event End Time</label>
              <input
              className={classes.inputEvent}
              id="timestamp"
              name="endEvent"
              type="datetime-local"
              value={eventEndTime}
              onChange={handleTimeInputChange}
            />
          </div>
        </>}
      </div>
      <h3 style={{padding:"1em"}}>{props.fuelTicketData?.data.event}</h3>
      {chart === 1 ? <FuelChart startTime={startTime} endTime={endTime}/> : changeChart()} 
       {props.fuelTicketData?.data.ticket_status === "Pending" && <div style={{ display: "flex",alignItems:"center",justifyContent:"center",marginTop:"1em",gap:"0.8em" }}>
        {props.closeTicketLoading ? <CircularProgress/>:
        <>
          <div> 
            <textarea
              style={{width: "300px"}}
              rows="3"
              cols="25"
              value={comment}
              placeholder="Comment"
              onChange={handleCommentChange}
            />
          </div>
        <button className={classes.fuelButton} onClick={handleReject}>Reject</button>
        <button className={`${classes.fuelButton} ${classes.acceptButton}`} onClick={handleAccept}>Accept</button>
        </>
        }
      </div>}
    </>
  );
};

const mapStateToProps = (state) => ({
  fuelTicketData: state.fuelTicketReducer.resFuelTicket,
  closeTicketData: state.fuelCloseTicketReducer.resCloseFuelTicket,
  closeTicketLoading: state.fuelCloseTicketReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fuelSubmit: ({ requestData }) => dispatch(fetchFuelRequest({ requestData })),
  threshold: ({ requestDevice }) =>
    dispatch(fetchThresholdRequest({ requestDevice })),
  summary: ({ requestDevice }) =>
    dispatch(fetchSummaryRequest({ requestDevice })),
  fuelTicket: ({ requestTicket }) =>
    dispatch(fetchFuelTicketRequest({ requestTicket })),
  closeTicket: ({ requestCloseFuelTicket }) =>
    dispatch(fuelCloseTicketRequest({ requestCloseFuelTicket })),
  closeTicketReset: () =>
    dispatch(fuelCloseTicketReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
