import * as types from "../types";

export const fetchGsmGpsRequest = ({ requestDevice }) => ({
  type: types.FETCH_GSM_GPS_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchGsmGpsSuccess = ({ gsmGps }) => ({
  type: types.FETCH_GSM_GPS_SUCCESS,
  payload: {
    gsmGps,
  },
});

export const fetchGsmGpsFailure = ({ error }) => ({
  type: types.FETCH_GSM_GPS_FAILURE,
  payload: {
    error: error.message,
  },
});
