import { takeLatest, call, put } from "redux-saga/effects";
import { fetchAppFailure, fetchAppSuccess } from "../actions/fetchAppAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchAppSaga(action) {
  try {
    yield put(clearErrors());
    let app = yield call(_api.deviceApp, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("app ", app);
    yield put(fetchAppSuccess({ app }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchAppFailure({ error: e }));
  }
}
export default function* fetchAppSaga() {
  yield takeLatest(types.FETCH_APP_REQUEST, asyncFetchAppSaga);
}
