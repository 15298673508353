import * as types from "../types";

const initialState = {
  loading: false,
  isLoggedIn: false,
  status: false,
  message: null,
  error: {},
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case types.LOGIN_REQUEST_SUCCESS:
      sessionStorage.setItem("loggedIn", true);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        status: action.status,
        message: action.message,
        error: {},
      };
    case types.LOGIN_REQUEST_FAILURE:
      sessionStorage.removeItem("loggedIn");
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        status: action.status,
        message: action.message,
        user: null,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGOUT_REQUEST_SUCCESS:
      sessionStorage.removeItem("loggedIn");
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        status: null,
        error: {},
        message: null,
      };
    case types.LOGOUT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
