import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchThresholdFailure,
  fetchThresholdSuccess,
} from "../actions/fetchThresholdValueAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchThresholdSaga(action) {
  try {
    yield put(clearErrors());
    let thresholdValue = yield call(_api.thresholdValue, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(fetchThresholdSuccess({ thresholdValue }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchThresholdFailure({ error: e }));
  }
}
export default function* fetchThresholdSaga() {
  yield takeLatest(types.FETCH_THRESHOLD_REQUEST, asyncFetchThresholdSaga);
}
