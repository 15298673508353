import * as types from "../types";

const initialState = {
  loading: false,
  replacementDetail: null,
  requestDevice: null,
  error: null,
};

export default function replacementDetailReducer(state = initialState, action) {
  switch (action.type) {
    case types.REPLACEMENT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        requestDevice: action.payload.requestDevice,
      };

    case types.REPLACEMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        replacementDetail: action.payload.replacementDetail,
      };

    case types.REPLACEMENT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        replacementDetail: null,
        requestDevice: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
