import { makeStyles } from "@material-ui/core/styles";

export const tripReplayStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  tilesWrapper: {
    maxWidth: "1400px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  bigTile: {
    minWidth: "900px",
    minHeight: "500px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    borderRadius: "10px",
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px",
    },
    "& > h3": {
      fontSize: "1.2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.4rem",
      },
    },
  },
  controlButton: {
    background: "#651fff ",
    height: "45px",
    width: "70px",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: "0 0.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  speedButton: {
    background: "#651fff ",
    height: "45px",
    width: "70px",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: "0 0.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  sliderButton: {
    padding: "0 0.5em",
    width: "200px",
    background: "#651fff",
    borderRadius: "4px",
    height: "45px",
    display: "flex",
    alignItems: "center",
  },

  timeButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.2em",
    background: "#651fff ",
    height: "45px",
    minWidth: "180px",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: "0 0.5em",
    fontSize: "1em",
  },

  summaryButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "0.1em",
    background: "#651fff ",
    minHeight: "45px",
    minWidth: "180px",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: "0.2em 0.5em",
  },
}));
