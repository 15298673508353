import React from "react";
import { fuelInstAttemptsStyles } from "./instAttempts.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

const InstAttempts = ({ fuelInstAttempts, fuelInstAttemptsLoading }) => {
  const classes = fuelInstAttemptsStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>N Installation Attempts:</p>
        {fuelInstAttemptsLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelInstAttempts ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelInstAttempts.n_installation_attempts[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Installation time in mins:</p>
        {fuelInstAttemptsLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelInstAttempts ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>
                {Object.values(fuelInstAttempts.installation_time_mins) > 0
                  ? fuelInstAttempts.installation_time_mins[0].toFixed(2)
                  : "NA"}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelInstAttempts: state.instAttemptsReducer.instAttempts,
  fuelInstAttemptsLoading: state.instAttemptsReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InstAttempts);
