import styled, { css } from "styled-components";
import { forwardRef } from "react";

//Container
const ContainerWrapper = forwardRef(
  ({ className, minHeight, ...props }, ref) => (
    <div
      className={className}
      ref={ref}
      style={{ minHeight: minHeight }}
      {...props}
    />
  )
);

const Container = styled(ContainerWrapper)`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 2em;
  min-height: ${(props) => props.minHeight};
`;

//Flex
const FlexWrapper = forwardRef(({ className, flex, ...props }, ref) => (
  <div ref={ref} className={className} {...props} />
));

const FlexGap = css`
  flex: ${(props) => props.flex};
`;

const RowGap = css`
  & > * + * {
    margin: 0 0 0 ${(props) => props.gap};
  }
`;

const ColumnGap = css`
  & > * + * {
    margin: ${(props) => props.gap} 0 0 0;
  }
`;

const Flex = styled(FlexWrapper)`
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  align-items: ${(props) => props.align ?? "center"};
  justify-content: ${(props) => props.justify ?? "space-between"};
  /* gap: ${(props) => props.gap}; */
  ${(props) => props.flex && FlexGap}
  ${({ direction, gap }) =>
    direction && gap && direction === "column" && ColumnGap}
  ${({ direction, gap }) =>
    direction || (gap && direction !== "column" && RowGap)}
`;

let IconWrapper = styled.span`
  width: ${(props) => props.size ?? "80px"};
  height: ${(props) => props.size ?? "80px"};
  border-radius: 50%;
  background: ${(props) => props.theme.colors["white-100"]};
  display: grid;
  place-content: center;
  & > img {
    width: ${(props) =>
      props.size ? props.size.split("px")[0] - 20 + "px" : "30px"};
  }
`;

export { Flex, Container, IconWrapper };
