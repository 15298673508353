import PointsOnMap from "../models/PointsOnMap";
import Patches from "../models/Patches";
import Commutes from "../models/Commutes";
import DaySummary from "../models/DaySummary";

const MAGIC_STRING =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*_+|:<>?-=.,/";
const BASE = 40;
const STATUS_MAGIC_STRING =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*_+|:<>?-=.,/";

export const getData = (data) => {
  return data ? data : "NA";
};

export const getDate = (today) => {
  var date = new Date(today),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
};

export const getDateDiff = (today) => {
  var date = new Date(today);
  var date2 = new Date();

  var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  var Difference_In_Time = date.getTime() - date2.getTime();

  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  if (Difference_In_Days > 10) {
    return (
      <p style={{ color: "black" }}>
        {[day, mnth, date.getFullYear()].join("-")}
      </p>
    );
  } else if (Difference_In_Days < 10 && Difference_In_Days > 0) {
    return (
      <p style={{ color: "green" }}>
        {[day, mnth, date.getFullYear()].join("-")}
      </p>
    );
  } else {
    return (
      <p style={{ color: "red" }}>
        {[day, mnth, date.getFullYear()].join("-")}
      </p>
    );
  }
};

export const getDateToday = (today) => {
  var date = new Date(today),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const culpritValue = (value) => {
  if (value === "") {
    return "Not a Culprit";
  } else if (value === true) {
    return "True";
  } else {
    return "False";
  }
};

export const getTimeSummary = (date) => {
  var milliSeconds = Date.parse(date);
  const dateObject = new Date(milliSeconds);
  const humanDateFormat = dateObject.toLocaleTimeString();

  const newDate =
    dateObject.toLocaleDateString("en-US", { day: "numeric" }) +
    "-" +
    dateObject.toLocaleDateString("en-US", { month: "numeric" }) +
    "-" +
    dateObject.toLocaleDateString("en-US", { year: "numeric" });

  const check = newDate + " , " + humanDateFormat;

  return check;
};

export const boolValue = (value) => {
  if (value === null) {
    return "NA";
  } else if (value === true) {
    return "True";
  } else {
    return "False";
  }
};

export const getPathColor = (value) => {
  console.log("value :", value);
  if (value === 0) {
    console.log("yellow");
    return "#FFFF00"; //yellow
  } else if (value === 1) {
    return "#00FF00"; //green
  } else if (value === 2) {
    return "#0000FF"; //blue
  } else if (value === 3) {
    return "#808080"; //grey
  } else if (value === 4) {
    return "#FFC0CB"; //pink
  } else if (value === 5) {
    return "#A52A2A"; //brown
  } else {
    return "#FF0000"; //red
  }
};

export const getRange = (arr) => {
  let ranges = [];
  let initial = 0;
  let end = 0;
  let startValue = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === startValue) {
      end++;
    } else {
      startValue = arr[i];
      ranges.push({
        start: initial,
        end: end,
        value: arr[i - 1],
      });
      initial = i;
      end = i;
    }
  }
  if (initial !== arr.length) {
    ranges.push({
      start: initial,
      end: arr.length - 1,
      value: arr[arr.length - 1],
    });
  }
  return ranges;
};

export const getCustomerColor = (data) => {
  if (data === 0) {
    return "#00FF00";
  } else if (data === 1) {
    return "#FF0000"; //red
  }
};

export const getTimeStampIST = (time) => {
  var milliSeconds = Date.parse(time);
  const dateObject = new Date(milliSeconds);
  const humanDateFormat = dateObject.toLocaleString();

  return humanDateFormat;
};

//Day summary
export const tripTimeFromDaySummary = (totalTime) => {
  let dataToDisplay = [];
  let secs = totalTime;
  let mins = Math.trunc(secs / 60);
  let hours = Math.trunc(mins / 60);

  if (hours < 10) {
    dataToDisplay.push("0");
    dataToDisplay.push(hours);
  } else {
    dataToDisplay.push(hours);
  }

  dataToDisplay.push(":");
  mins %= 60;
  if (mins < 10) {
    dataToDisplay.push("0");
    dataToDisplay.push(mins);
  } else {
    dataToDisplay.push(mins);
  }
  return dataToDisplay.join("");
};

//Day summary time difference
export const getTimeDiff = (deviceTime) => {
  let deviceLastSeen = new Date(deviceTime);
  let currentTime = new Date();
  let diffInMs = currentTime.getTime() - deviceLastSeen.getTime();

  const diffTime = [];

  const msInDays = 1000 * 60 * 60 * 24;
  const msInHour = 1000 * 60 * 60;
  const msInMinute = 1000 * 60;

  let diffInMins = Math.trunc(diffInMs / msInMinute);
  let diffInHrs = Math.trunc(diffInMs / msInHour);
  let diffInDays = Math.trunc(diffInMs / msInDays);
  let diffInMonth = Math.trunc(diffInDays / 30);
  let diffInYear = Math.trunc(diffInMonth / 12);

  if (diffInMins < 60) {
    if (diffInMins > 1) {
      diffTime.push(diffInMins, "Mins", " ", "Ago");
    } else if (diffInMins === 0) {
      diffTime.push("Just Now");
    } else {
      diffTime.push(diffInMins, "Min", " ", "Ago");
    }
  } else if (diffInHrs < 24) {
    if (diffInHrs > 1) {
      diffTime.push(diffInHrs, "Hours", " ", "Ago");
    } else {
      diffTime.push(diffInHrs, "Hour", " ", "Ago");
    }
  } else if (diffInDays < 30) {
    if (diffInDays > 1) {
      diffTime.push(diffInDays, "Days", " ", "Ago");
    } else {
      diffTime.push(diffInDays, "Day", " ", "Ago");
    }
  } else if (diffInMonth < 12) {
    if (diffInMonth > 1) {
      diffTime.push(diffInMonth, "Months", " ", "Ago");
    } else {
      diffTime.push(diffInMonth, "Month", " ", "Ago");
    }
  } else {
    if (diffInYear > 1) {
      diffTime.push(diffInYear, "Years", " ", "Ago");
    } else {
      diffTime.push(diffInYear, "Year ", " ", "Ago");
    }
  }

  return diffTime.join(" ");
};

export const getArea = (area) => {
  const SQR_MTS_ACRES = 0.000247105;
  let areas = (area * SQR_MTS_ACRES).toFixed(2);

  return areas;
};

export const parseDayDetails = (data) => {
  const commutesParsed = addArraysToDecompressedData(data.commutes);
  const patchesParsed = addArraysToDecompressedData(data.patches);
  const replayParsed = addArraysToDecompressedReplay(data.replay);
  // console.log("replayParsed :", replayParsed);
  data["commutes"] = formPointsFromCommutesOrPatches(commutesParsed);
  data["patches"] = formPointsFromCommutesOrPatches(patchesParsed);
  data["replay"] = formPointsFromReplay(replayParsed);
  // console.log("data :", data);
  return data;
};

function addArraysToDecompressedData(commuteOrPatch) {
  let refArray = commuteOrPatch.ref;
  let ref_lat = refArray[0];
  let ref_lon = refArray[1];
  const commute_lat_array = getFinalArray(commuteOrPatch.lat, ref_lat);
  const commute_lon_array = getFinalArray(commuteOrPatch.lon, ref_lon);
  commuteOrPatch["lat"] = commute_lat_array;
  commuteOrPatch["lon"] = commute_lon_array;
  delete commuteOrPatch["ref"];
  return commuteOrPatch;
}

function addArraysToDecompressedReplay(replay) {
  let ref_lat = [replay.ref_lat];
  let ref_long = [replay.ref_lon];
  const commute_lat_array = getFinalReplayArray(replay.lats, ref_lat);
  const commute_lon_array = getFinalReplayArray(replay.lons, ref_long);
  // console.log("replay : ", replay);
  const ts = getCompleteTripTimeStamp(replay.ts, replay.ref_ts);
  const status = getTractorIgnitionStatus(replay.status);
  replay["lats"] = commute_lat_array;
  replay["lons"] = commute_lon_array;
  replay["ts"] = ts;
  replay["status"] = status;
  delete replay["ref_lat"];
  return replay;
}

function formPointsFromCommutesOrPatches(commutesOrPatches) {
  const lats = commutesOrPatches.lat;
  const lngs = commutesOrPatches.lon;
  const ids = commutesOrPatches.id;
  const lat = [];
  const lon = [];
  const id = [];

  for (let i = 0; i < lats.length; i++) {
    lat.push(lats[i]);
    lon.push(lngs[i]);
    id.push(ids[i]);
  }

  const points = new PointsOnMap();
  points.lats = lat;
  points.lngs = lon;
  points.ids = id;

  return points;
}

function formPointsFromReplay(replay) {
  const lats = replay.lats;
  const lngs = replay.lons;

  const lat = [];
  const lon = [];
  const id = [];
  const ts = replay.ts;
  const status = replay.status;
  for (let i = 0; i < lats.length; i++) {
    lat.push(lats[i]);
    lon.push(lngs[i]);
    id.push(id[i]);
    ts.push(ts[i]);
    status.push(status[i]);
  }
  const points = new PointsOnMap();
  points.lats = lat;
  points.lngs = lon;
  points.ids = id;
  points.ts = ts;
  points.status = status;
  return points;
}

function getFinalArray(inputStr, refArr) {
  const opArray = [];

  const subStrArray = getSubStrings(inputStr);

  for (let i = 0; i < subStrArray.length; i++) {
    let subStr = subStrArray[i];
    let tempArray = getDiffArray(subStr, refArr[i]);
    opArray.push(tempArray);
  }
  return opArray;
}

function getFinalReplayArray(inputStr, refArr) {
  let outputArray = getIntegerArray(inputStr, 36);
  let diffOut = getBaseValueDiff(outputArray);
  let updateDoubleData = getDifferenceData(diffOut);
  let finalData = [];
  finalData.push(refArr);
  for (let i = 1; i < updateDoubleData.length; i++) {
    finalData.push(
      parseFloat(finalData[i - 1]) + parseFloat(updateDoubleData[i])
    );
  }
  return finalData;
}

function getCompleteTripTimeStamp(ts, ref_time) {
  let output_array = getIntegerArray(ts, 36);
  let finalData = [];
  finalData.push(convertNotificationTimeStampToMillis(ref_time));
  for (let i = 1; i < output_array.length; i++) {
    finalData.push(finalData[i - 1] + output_array[i] * 1000);
  }
  return finalData;
}

function getTractorIgnitionStatus(status) {
  let count = [];
  let holder = [];
  let currentStatus = parseInt(status[0].toString());
  for (let i = 1; i < status.length; i++) {
    if (isDigit(status[i])) {
      updateStatusArray(getIntValue(holder), currentStatus, count);
      holder = [];
      currentStatus = parseInt(status[i].toString());
    } else {
      let x = STATUS_MAGIC_STRING.indexOf(status[i]);
      if (x < BASE) {
        holder.push(x);
      } else {
        holder.push(x - BASE);
      }
      if (i === status.length - 1) {
        updateStatusArray(getIntValue(holder), currentStatus, count);
      }
    }
  }
  return count;
}

function getSubStrings(inputStr) {
  const positions = [];
  let endIndexArr = [];
  const subStrArray = [];
  let x;

  for (let i = 0; i < inputStr.length; i++) {
    x = inputStr.charAt(i);

    if (x === MAGIC_STRING.charAt(2 * BASE + 1)) {
      positions.push(i);
    }
  }

  positions.unshift(-1);
  endIndexArr = positions;
  let start_ind, end_ind;
  let sub_str;

  for (let i = 0; i < endIndexArr.length - 1; i++) {
    start_ind = endIndexArr[i] + 1;
    end_ind = endIndexArr[i + 1];
    sub_str = inputStr.substring(start_ind, end_ind);
    subStrArray.push(sub_str);
  }

  return subStrArray;
}

function getDiffArray(subStr, refArr) {
  const pos_arr = [];
  const end_ind_arr = [];
  const diffArr = [];

  for (let i = 0; i < subStr.length; i++) {
    pos_arr.push(MAGIC_STRING.indexOf(subStr.charAt(i)));
  }

  for (let i = 0; i < pos_arr.length; i++) {
    if (pos_arr[i] < BASE) {
      end_ind_arr.push(i);
    }
  }

  end_ind_arr.unshift(-1);

  let length_arr = getDifferenceToNextNumber(end_ind_arr);
  let start_ind = 0;
  const base_arr = [];

  for (let i = 0; i < length_arr.length; i++) {
    let end_ind = start_ind + length_arr[i];

    let temp = pos_arr.slice(start_ind, end_ind);

    for (let j = 0; j < temp.length - 1; j++) {
      temp[j] = temp[j] - BASE;
    }
    base_arr.push(temp);
    start_ind = end_ind;
  }

  for (let i = 0; i < base_arr.length; i++) {
    diffArr.push(0);
  }

  let sum = 0;

  for (let i = 0; i < base_arr.length; i++) {
    let tempArr = base_arr[i];
    sum = 0;
    for (let j = 0; j < tempArr.length; j++) {
      sum += tempArr[j] * Math.pow(BASE, tempArr.length - j - 1);
    }
    diffArr[i] = sum;
  }

  let shift = diffArr[0];
  for (let i = 0; i < diffArr.length; i++) {
    diffArr[i] = diffArr[i] - shift;
  }

  const outArray = [];
  for (let i = 0; i < diffArr.length + 1; i++) {
    if (i === 0) {
      outArray.push(Math.trunc(refArr * 1000000));
    } else {
      outArray.push(0);
    }
  }

  for (let i = 1; i < diffArr.length; i++) {
    outArray[i] = Math.trunc(outArray[i - 1] + diffArr[i]);
  }

  outArray.pop();
  const opDoubleArray = [];
  for (let i = 0; i < outArray.length; i++) {
    let a = outArray[i] / 1000000;
    opDoubleArray.push(a);
  }
  return opDoubleArray;
}

function getIntegerArray(inputString, BASE) {
  let position = [];
  let x;
  let holder = [];
  for (let i = 0; i < inputString.length; i++) {
    x = MAGIC_STRING.indexOf(inputString.charAt(i));
    if (x < BASE) {
      holder.push(x);
    } else {
      holder.push(x - BASE);
      position.push(getIntValue(holder));
      holder = [];
    }
  }
  return position;
}

function getBaseValueDiff(originalArray) {
  let result = [];
  if (originalArray.length > 1) {
    for (let i = 0; i < originalArray.length; i++) {
      result.push(originalArray[i] - originalArray[0]);
    }
    return result;
  } else {
    return originalArray;
  }
}
function getDifferenceData(dataList) {
  let updated = [];
  for (let i = 0; i < dataList.length; i++) {
    let val = dataList[i] / 100000;
    updated.push(val);
  }
  return updated;
}

const convertNotificationTimeStampToMillis = (timeStamp) =>
  new Date(timeStamp + "Z").getTime();

const isDigit = (c) => "1234567890".includes(c);

function updateStatusArray(intValue, currentStatus, count) {
  for (let i = 0; i < intValue; i++) {
    count.push(currentStatus);
  }
}

function getIntValue(list) {
  let resultSum = 0;
  list.reverse();
  for (let i = 0; i < list.length; i++) {
    resultSum = resultSum + Math.pow(36, i) * list[i];
  }
  return resultSum;
}

function getDifferenceToNextNumber(originalArray) {
  const result = [];
  if (originalArray.length > 1) {
    for (let i = 1; i <= originalArray.length - 1; i++) {
      result.push(originalArray[i] - originalArray[i - 1]);
    }
    return result;
  } else {
    return originalArray;
  }
}

export const parsedFuelAndStatus = (realtime, tractor) => {
  const parsed = [];
  if (realtime.status >= 0) {
    let int = parseInt(realtime.status);
    let some = Number(int).toString(2);
    //pad 0 so that length is 32 and replace " " with 0
    const charArray = [...some];

    let status = updateStatusReg(charArray);
    if (status === "NO") {
      if (realtime.speed === 0) {
        status = "IDLE";
      } else if (realtime.time < 300000) {
        status = "ON";
      } else {
        status = "--";
      }
    }

    parsed.push(status);
  }

  const {
    fuel_range_params: { start_fuel, end_fuel, fuel_accuracy },
  } = tractor;

  const fuel = computeFuelToDisplay(
    realtime.fuel_level_dashboard,
    tractor.app_mask_flags.fuel,
    start_fuel,
    end_fuel,
    fuel_accuracy
  );
  parsed.push(fuel);

  return parsed;
};

function updateStatusReg(charArray) {
  //0 - trip on/off
  //1 - ign on/off
  //2 - tractor status idle/running
  //3 - pto on/off
  //4 - ploughing on/off
  let status = "";
  if (charArray[charArray.length - 1] === "1") {
    if (charArray[charArray.length - 3] === "1") {
      if (charArray[charArray.length - 4] === "1") {
        status = "ON";
      } else {
        status = "ON";
      }
    } else {
      status = "IDLE";
    }
  } else {
    status = "OFF";
  }
  return status;
}

function computeFuelToDisplay(
  fuelInLitres,
  fuelMask,
  startFuel,
  endFuel,
  fuelAccuracy
) {
  if (fuelMask === 1) {
    //show Fuel
    if (fuelInLitres < startFuel) return "< " + startFuel;
    else if (fuelInLitres >= endFuel) return "> " + endFuel;
    else if (fuelAccuracy > 0) {
      let stepsToCover = fuelInLitres - startFuel;
      let remainderSteps = stepsToCover % fuelAccuracy;
      let rangeStart = fuelInLitres - remainderSteps;
      let rangeEnd = rangeStart + fuelAccuracy;
      return rangeStart + " - " + rangeEnd;
    } else {
      return fuelInLitres.toString();
    }
  } else {
    return "-";
  }
}

export const parseDaySummary = (daySummary) => {
  const { data } = daySummary;
  let commutes = [];
  let patches = [];
  let com = data.commutes;
  let pat = data.patches;
  for (let i = 0; i < com.id.length; i++) {
    let commute = new Commutes(
      com.start[i],
      com.p20[i],
      com.p40[i],
      com.p60[i],
      com.p80[i],
      com.end[i],
      com.id[i]
    );
    commutes.push(commute);
  }

  for (let i = 0; i < pat.id.length; i++) {
    let patch = new Patches(
      pat.running_time[i],
      pat.patch_area[i],
      pat.lat_centroid[i],
      pat.lon_centroid[i],
      pat.id[i],
      pat.fuel_consumed[i],
      pat.boundary_radius_avg[i],
      pat.patch_tags[i]
    );
    patches.push(patch);
  }

  let finalDaySummary = new DaySummary(
    data.device_fk_id + data.date,
    data.date,
    data.computation_id,
    data.idling_time,
    data.job_time,
    data.haulage_time,
    data.distance,
    data.total_fuel,
    data.total_fuel_lit,
    data.fuel_params.max,
    data.fuel_params.min,
    data.area,
    data.device_fk_id,
    data.details_flag,
    patches,
    commutes
  );

  return finalDaySummary;
};
