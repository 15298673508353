import React, { useState, useEffect } from "react";
import { fuelGsmGpsStyles } from "./gsmGps.styles";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const Gsmgps = ({ fuelGsmGps }) => {
  const classes = fuelGsmGpsStyles();
  const [fuelGsm, setFuelGsm] = useState([]);

  useEffect(() => {
    let fuelGsmData = [];
    if (fuelGsmGps) {
      let created = Object.values(fuelGsmGps.created_at);
      let lock_percentage = Object.values(fuelGsmGps.Lock_Percentage);
      let rtpp = Object.values(fuelGsmGps.RTPP);
      for (let i = 0; i < created.length; i++) {
        fuelGsmData.push({
          created_at: created[i],
          lockPercentage: lock_percentage[i],
          rtppData: rtpp[i],
        });
      }
      setFuelGsm(fuelGsmData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelGsmGps]);

  return (
    <div style={{ width: "100%", overflowY: "scroll", height: "300px" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Created at</TableCell>
              <TableCell align="right">Location</TableCell>
              <TableCell align="right">Network</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelGsm.slice(0, 10).map((row) => (
              <TableRow key={row.created_at}>
                <TableCell component="th" scope="row">
                  {row.created_at}
                </TableCell>
                <TableCell align="right">{row.lockPercentage}</TableCell>
                <TableCell align="right">{row.rtppData}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelGsmGps: state.gsmGpsReducer.gsmGps,
  fuelGsmGpsLoading: state.gsmGpsReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Gsmgps);
