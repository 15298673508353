import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { parseDaySummary, tripTimeFromDaySummary } from "../../utils";

import {
  daySummarySuccess,
  daySummaryFailure,
} from "../actions/fetchDaySummaryAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";

const SQR_MTS_ACRES = 0.000247105;

function* asyncDaySummary(action) {
  let data = "NA",
    area = "NA",
    totalTime = "NA",
    kms = "NA",
    dailyStatus,
    commutes,
    patches,
    replay;
  try {
    yield put(clearErrors());
    const daySummary = yield call(_api.fetchDaySummary, {
      requestData: action.payload.requestData,
    });
    // console.log("fetchDaySummary : ", daySummary);

    if (daySummary && daySummary.status) {
      let commutePatchesLength = 0;
      for (let key of Object.keys(daySummary.data.commutes)) {
        commutePatchesLength =
          commutePatchesLength + daySummary.data.commutes[key].length;
      }
      if (commutePatchesLength > 0) {
        dailyStatus = daySummary.status;
        data = parseDaySummary(daySummary);

        area = (data.area * SQR_MTS_ACRES).toFixed(2);
        totalTime = tripTimeFromDaySummary(
          data.haulage_time + data.idling_time + data.job_time
        );
        kms = (data.distance / 1000).toFixed(2);

        const res2 = yield call(_api.fetchDayDetail, {
          deviceId: data.device_fk_id,
          date: action.payload.requestData.date,
        });
        // console.log("res2 : ", res2);

        commutes = res2.commutes;
        patches = res2.patches;
        replay = res2.replay;
      }
    }

    yield put(
      daySummarySuccess({
        daySummary,
        commutes,
        patches,
        replay,
        dailyStatus,
        area,
        totalTime,
        kms,
      })
    );
  } catch (e) {
    // console.log(e);
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(daySummaryFailure(e));
  }
}

export default function* daySummaryRequestSaga() {
  yield takeLatest(types.DAY_SUMMARY_REQUEST, asyncDaySummary);
}
