import React from "react";
import { ReplacementDetailStyles } from "./replacementDetail.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getTimeSummary } from "../../utils";

const ReplacementDetail = ({ replacementDetailData, replacementDetailLoading }) => {
  const classes = ReplacementDetailStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>Device ID:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.device_id}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Action Date:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.action_date !== null ? getTimeSummary(replacementDetailData?.action_date):"NA"}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Effect From Date:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.effect_from_date}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>New Label ID:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.new_label_id}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>New IMEI:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.["new imei"]}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Old IMEI:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.["old imei"]}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>New Sim CCID:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.new_sim_ccid}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Old Sim CCID:</p>
        {replacementDetailLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!replacementDetailData ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{replacementDetailData?.old_sim_ccid}</h4>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  replacementDetailData: state.replacementDetailReducer.replacementDetail,
  replacementDetailLoading: state.replacementDetailReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementDetail);
