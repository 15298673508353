import React, { useState } from "react";
import { tryStyles } from "./try.styles";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { InputField } from "../../elements";
import TextField from "@material-ui/core/TextField";

import Dashboard from "../Dashboard";
import Realtime from "../Realtime";
import FuelSummary from "../FuelSummary";
import Summary from "../Summary";
import FuelModel from "../FuelModel";
import AppData from "../AppData";
import InstAttempts from "../InstAttempts";
import DeviceMount from "../DeviceMounting";
import Antennta from "../Antennta";
import Alternator from "../Alternator";
import Connector from "../Connector";
import FuelCulprit from "../FuelCulprit";
import Gsmgps from "../GsmGps";
import Ticket from "../Ticket";
import TripReplay from "../TripReplay";
import QualityChart from "../QualityChart";
import FirebaseData from "../FirebaseData";
import SimDetails from "../SimDetails";
import ReplacementDetail from "../ReplacementDetail";
import { AccordionComponent } from "../../elements";

import { fetchFuelRequest } from "../../store/actions/fetchfuelsAction";
import { fetchRealtimeRequest } from "../../store/actions/fetchRealtimeAction";
import { fetchSummaryRequest } from "../../store/actions/fetchSummaryAction";
import { fetchFuelSummaryRequest } from "../../store/actions/fetchFuelSummaryAction";
import { fetchFuelModelRequest } from "../../store/actions/fetchFuelModelAction";
import { fetchFuelCulpritRequest } from "../../store/actions/fetchFuelCulpritAction";
import { fetchTicketRequest } from "../../store/actions/fetchTicketAction";
import { fetchAppRequest } from "../../store/actions/fetchAppAction";
import { fetchInstAttemptsRequest } from "../../store/actions/fetchInstAttemptsAction";
import { fetchGsmGpsRequest } from "../../store/actions/fetchGsmGpsAction";
import { fetchInstImageRequest } from "../../store/actions/fetchInstImageAction";
import { fetchFirebaseDataRequest } from "../../store/actions/fetchFirebaseDataAction";
import { fetchThresholdRequest } from "../../store/actions/fetchThresholdValueAction";
import { daySummaryRequest } from "../../store/actions/fetchDaySummaryAction";
import { logoutRequest } from "../../store/actions/logoutAction";
import { simStatusRequest } from '../../store/actions/simStatusAction'
import { replacementDetailRequest } from '../../store/actions/replacemetDetailAction'

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { FaRedoAlt } from "react-icons/fa";

import { getDateToday } from "../../utils";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function Design({
  fuelLoading,
  fueldata,
  fuelsummary,
  summaryLoading,
  logout,
  ...props
}) {
  const classes = tryStyles();
  const [deviceId, setDeviceId] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showGraph, setShowGraph] = useState(false);

  let deviceData = deviceId;
  var today = getDateToday(selectedDate);

  const handleChange = (e) => {
    setSelectedDate(e.target.value);
    let deviceDate = e.target.value;
    if(deviceId !== 0 && deviceId !== undefined){
      let requestData = {
      device_id: deviceId,
      date: deviceDate,
    };
    props.fuelSubmit({ requestData });
    props.getDaySummary({ requestData });
    }
  };

  const handleShowGraph = () => {
    if(deviceId !== 0 && deviceId !== undefined){
        let requestData = {
          device_id: deviceId,
          date: today,
        };
        props.getDaySummary({ requestData });
        props.fuelSubmit({ requestData });
        setShowGraph((prev) => !prev);
    }
  };

  const handleReset = () => {
    let requestData = {
      device_id: deviceId,
      date: today,
    };
    if(deviceId !== undefined && deviceId !== ""){
      let requestDevice = {
      device_id: deviceId,
    };
    props.getDaySummary({ requestData });
    props.fuelSubmit({ requestData });
    props.realtimeSubmit({ requestDevice });
    props.summary({ requestDevice });
    props.fuelSummary({ requestDevice });
    props.fuelModel({ requestDevice });
    props.fuelCulprit({ requestDevice });
    props.ticket({ requestDevice });
    props.app({ requestDevice });
    props.instAttempts({ requestDevice });
    props.gsmGps({ requestDevice });
    props.instImage({ requestDevice });
    props.firebase({ requestDevice });
    props.threshold({ requestDevice });
    props.simRequest({requestDevice});
    props.replacementDetailReq({requestDevice});
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.navbar}>
        <h2 style={{ color: "#651fff" }}>Internal Debug Dashboard</h2>
        <div style={{ display: "flex", gap: "2em" }}>
          <Formik
            initialValues={{
              device_id: "",
            }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              let { deviceId } = values;
              setDeviceId(deviceId);
              if(deviceId !== undefined && deviceId !== ""){
                let requestDevice = {
                device_id: deviceId,
              };
              setShowGraph(false);
              // setSelectedDate(new Date());
              props.realtimeSubmit({ requestDevice });
              props.summary({ requestDevice });
              props.fuelSummary({ requestDevice });
              props.fuelModel({ requestDevice });
              props.fuelCulprit({ requestDevice });
              props.ticket({ requestDevice });
              props.app({ requestDevice });
              props.instAttempts({ requestDevice });
              props.gsmGps({ requestDevice });
              props.instImage({ requestDevice });
              props.firebase({ requestDevice });
              props.threshold({ requestDevice });
              props.simRequest({requestDevice});
              props.replacementDetailReq({requestDevice});
              setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <div>
                  <Field
                    name="deviceId"
                    id="device-id"
                    label="Device id"
                    labelWidth={65}
                    type="number"
                    component={InputField}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <form
            noValidate
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "40%",
              color: "blue",
            }}
          >
            <TextField
              id="date"
              type="date"
              value={selectedDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </form>
        </div>
        <button
          style={{
            background: "#651fff ",
            height: "45px",
            width: "15em",
            borderRadius: "4px",
            color: "#FFFFFF",
            padding: "0 0.5em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleShowGraph}
        >
          {!showGraph ? (
            <>Show Graphs</>
          ) : (
            <>
              <>Hide Graphs</>
            </>
          )}
        </button>
        <div onClick={() => logout()}>
          <Tooltip title="Logout">
            <IconButton>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {(deviceId === 0 || deviceId === undefined || deviceId === "") ? (
        <div>
          <h1
            style={{ textAlign: "center", margin: "3em auto", padding: "8em" }}
          >
            Enter a Device ID
          </h1>
        </div>
      ) : (
        <>
          {showGraph && (
            <div className={classes.tilesWrapper}>
              <div className={classes.map}>
                <TripReplay />
              </div>
              <div style={{ width: "100%", margin: "1em" }}>
                <Dashboard device={deviceData} />
              </div>
              <div style={{ width: "100%", margin: "1em" }}>
                <QualityChart device={deviceData} />
              </div>
            </div>
          )}
          <div className={classes.tilesWrapper}>
            <div className={classes.tiles1}>
              <Summary />
            </div>
            <div className={classes.tiles2}>
              <Realtime />
            </div>
            <div className={classes.tiles4}>
              <div style={{ margin: "1em" }}>
                <AccordionComponent
                  header={"Fuel Summary"}
                  value={<FuelSummary />}
                />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent
                  header={"Fuel Model"}
                  value={<FuelModel />}
                />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent header={"App"} value={<AppData />} />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent header={"Sim"} value={<SimDetails />} />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent header={"Replacement Detail"} value={<ReplacementDetail />} />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent
                  header={"Installation Attempts"}
                  value={<InstAttempts />}
                />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent header={"GSM GPS"} value={<Gsmgps />} />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent
                  header={"Fuel Culprit"}
                  value={<FuelCulprit />}
                />
              </div>
              <div style={{ margin: "1em" }}>
                <AccordionComponent
                  header={"Firebase Data"}
                  value={<FirebaseData />}
                />
              </div>
            </div>
          </div>
          <div className={classes.tilesWrapper}>
            <div className={classes.newTiles9}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    Installation Image
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "1em",
                    }}
                  >
                    <div>
                      <AccordionComponent
                        header={"Device Mounting Image"}
                        value={<DeviceMount />}
                      />
                    </div>
                    <div>
                      <AccordionComponent
                        header={"GPS Antennta Image"}
                        value={<Antennta />}
                      />
                    </div>
                    <div>
                      <AccordionComponent
                        header={"Alternator Image"}
                        value={<Alternator />}
                      />
                    </div>
                    <div>
                      <AccordionComponent
                        header={"Fuel Connection Image"}
                        value={<Connector />}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={classes.tilesWrapper}>
            <div className={classes.tiles8}>
              <AccordionComponent header={"Tickets"} value={<Ticket />} />
            </div>
          </div>
          <div className={classes.float} onClick={handleReset}>
            <h1 style={{ padding: "10px" }}>
              <FaRedoAlt />
            </h1>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fuelLoading: state.fuelReducer.loading,
  fuelmodel: state.fuelModelReducer.fuelModel,
  fueldata: state.fuelReducer.fuel,
  fuelsummary: state.fuelSummaryReducer.fuelSummary,
  summaryLoading: state.fuelSummaryReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fuelSubmit: ({ requestData }) => dispatch(fetchFuelRequest({ requestData })),
  realtimeSubmit: ({ requestDevice }) =>
    dispatch(fetchRealtimeRequest({ requestDevice })),
  summary: ({ requestDevice }) =>
    dispatch(fetchSummaryRequest({ requestDevice })),
  fuelSummary: ({ requestDevice }) =>
    dispatch(fetchFuelSummaryRequest({ requestDevice })),
  fuelModel: ({ requestDevice }) =>
    dispatch(fetchFuelModelRequest({ requestDevice })),
  fuelCulprit: ({ requestDevice }) =>
    dispatch(fetchFuelCulpritRequest({ requestDevice })),
  ticket: ({ requestDevice }) =>
    dispatch(fetchTicketRequest({ requestDevice })),
  app: ({ requestDevice }) => dispatch(fetchAppRequest({ requestDevice })),
  instAttempts: ({ requestDevice }) =>
    dispatch(fetchInstAttemptsRequest({ requestDevice })),
  gsmGps: ({ requestDevice }) =>
    dispatch(fetchGsmGpsRequest({ requestDevice })),
  instImage: ({ requestDevice }) =>
    dispatch(fetchInstImageRequest({ requestDevice })),
  firebase: ({ requestDevice }) =>
    dispatch(fetchFirebaseDataRequest({ requestDevice })),
  threshold: ({ requestDevice }) =>
    dispatch(fetchThresholdRequest({ requestDevice })),
  getDaySummary: ({ requestData }) =>
    dispatch(daySummaryRequest({ requestData })),
  simRequest: ({ requestDevice }) =>
    dispatch(simStatusRequest({ requestDevice })),
  replacementDetailReq: ({ requestDevice }) =>
    dispatch(replacementDetailRequest({ requestDevice })),
  logout: () => {
    dispatch(logoutRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Design);
