import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTime } from "../utils/getTime";

// Resolves charts dependancy
charts(FusionCharts);

function FuelChart({ fuelLoading, fueldata, startTime, endTime, fuelTicketData }) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (fueldata && fueldata.message === undefined) {
      let timeStampData = Object.values(fueldata.time_stamp);
      let fuelLevelData = Object.values(fueldata.fuel_level_dashboard);
      let tractorStatus = Object.values(fueldata.status);
      let maxFuelValue = Math.max(...fuelLevelData);
      let data = [];
      let seriesData2 = [];
      let startRange = getTime(fuelTicketData?.data.event_start_ts);
      let endRange = getTime(fuelTicketData?.data.event_end_ts);
      let count = 0;
      for (let i = startTime*60; i < endTime*60; i++) {
        if (fuelLevelData[i] !== -1) {
          count++;
        }
      }
      let size;
      if (count < 100) {
        size = "6";
      } else {
        size = "3";
      }

      for (let i = startTime * 60; i < endTime * 60; i++) {
         if (fuelLevelData[i] < 1) {
          data.push({
            label: timeStampData[i],
            value: fuelLevelData[i] < 0 ? 0:fuelLevelData[i],
            drawAnchors: "1",
            anchorRadius: "1",
            anchorBorderThickness: "3",
            anchorBorderColor: "#FF0000",
            anchorSides: "3",
            anchorBgColor: "#d3f7ff",
          });
        } else {
            data.push({
              label: timeStampData[i],
              value: fuelLevelData[i],
              drawAnchors: "1",
              anchorRadius: "1",
              anchorBorderThickness: size,
              anchorBorderColor: (tractorStatus[i] & 1) === 1 ? "#FFCC00":"#FF0000",
              anchorSides: "1",
              anchorBgColor: (tractorStatus[i] & 1) === 1 ? "#FFCC00":"#FF0000",
            });
          
        }

        if (timeStampData[i] >= startRange && timeStampData[i] <= endRange) {
            seriesData2.push({
              label: timeStampData[i],
              value: maxFuelValue,
              anchorRadius: "1",
              anchorBorderThickness: "1",
              anchorBorderColor: "#00FF00",
              anchorBgColor: "#00FF00",
            });
          } else {
            seriesData2.push({ label: timeStampData[i], value: 0 });
        }
      }

      setSeries([
        {
          seriesname: `${fuelTicketData?.data.event}`,
          data: seriesData2,
          color: fuelTicketData?.data.event === "Refuel" ? "#00ff00":"#FF0000",
          areaColor: fuelTicketData?.data.event === "Refuel" ? "#00ff00":"#FF0000", 
          renderAs: "line",
          dashed: "1", 
          lineThickness: "2", 
        },
        {
          seriesname: "Fuel Data",
          data: data,
          color: "#FFCC00", 
          areaColor: "#FFCC00",
        },
      ]);
    }
  }, [fueldata, fuelTicketData, startTime, endTime]);

  const chartConfigs = {
    chart: {
      yaxisname: "Fuel",
      xAxisName:"TimeStamp",
      drawcrossline: "1",
      numbersuffix: "L",
      showValues: "0",
      showBorder: "0",
      showCanvasBorder: "0",
      drawAnchors: "0",
      theme: "umber",
      xAxisNameFontSize: "20",
      xAxisNameFontColor: "#651fff",
      yAxisNameFontSize: "20",
      yAxisNameFontColor: "#651fff",
      labelStep: "60",
      baseFontSize: "11",
    },
    categories: [
      {
        category: series[0]?.data.map((d) => ({ label: d.label })),
      },
    ],
    dataset: series,
  };

  function ChartData() {
    if (fuelLoading) {
      return (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      );
    } else if (
      fueldata &&
      fueldata.message === undefined &&
      Object.values(fueldata.time_stamp).length > 0
    ) {
      return (
        <ReactFusioncharts
          type="msarea"
          dataFormat="JSON"
          width="100%"
          height="600"
          dataSource={chartConfigs}
        />
      );
    } else {
      return <h1 style={{ textAlign: "center", padding: "5em" }}>{fueldata?.message}</h1>;
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {!fueldata ? (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      ) : (
        ChartData()
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fueldata: state.fuelReducer.fuel,
  fuelLoading: state.fuelReducer.loading,
  fuelTicketData: state.fuelTicketReducer.resFuelTicket,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelChart);
