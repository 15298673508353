import * as types from "../types";

const initialState = {
  loading: false,
  fuel: null,
  requestData: null,
  error: null,
};

export default function fetchFuelReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FUEL_REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.payload.requestData,
      };

    case types.FETCH_FUEL_SUCCESS:
      return {
        ...state,
        loading: false,
        fuel: action.payload.fuel,
      };

    case types.FETCH_FUEL_FAILURE:
      return {
        ...state,
        loading: false,
        fuel: null,
        requestData: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
