import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchSummaryFailure,
  fetchSummarySuccess,
} from "../actions/fetchSummaryAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchSummarySaga(action) {
  try {
    yield put(clearErrors());
    let summary = yield call(_api.deviceSummary, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("summary ", summary);
    yield put(fetchSummarySuccess({ summary }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchSummaryFailure({ error: e }));
  }
}
export default function* fetchSummarySaga() {
  yield takeLatest(types.FETCH_SUMMARY_REQUEST, asyncFetchSummarySaga);
}
