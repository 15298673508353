import React from "react";
import { SimDetailStyles } from "./simData.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

const SimDetails = ({ sim, simLoading }) => {
  const classes = SimDetailStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>Device ID:</p>
        {simLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!sim ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{sim?.device_id}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Sim CCID:</p>
        {simLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!sim ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{sim?.sim_ccid}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Operator:</p>
        {simLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!sim ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{sim?.operator}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Sim Status:</p>
        {simLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!sim ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{sim?.sim_status}</h4>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sim: state.simStatusReducer.sim,
  simLoading: state.simStatusReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SimDetails);
