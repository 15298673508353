import React from "react";
import { FirebaseDataStyles } from "./firebaseData.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

const FirebaseData = ({ firebase, firebaseLoading, ...props }) => {
  const classes = FirebaseDataStyles();

  return (
    <div>
      <div className={classes.text}>
        <p>Is app open?:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Is app open?"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Engine Status:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Engine Status"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Last Seen At:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Last Seen At"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Time Stamp (IST):</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Time Stamp (IST)"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Fuel (litres):</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Fuel (litres)"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Speed:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Speed"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Latitude:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Latitude"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Longitude:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["Longitude"]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>GPS Lock:</p>
        {firebaseLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!firebase ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{firebase["GPS Lock"]}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  firebase: state.firebaseReducer.firebaseData,
  firebaseLoading: state.firebaseReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseData);
