import * as types from "../types";

export const fetchRealtimeRequest = ({ requestDevice }) => ({
  type: types.FETCH_REALTIME_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchRealtimeSuccess = ({ realtime }) => ({
  type: types.FETCH_REALTIME_SUCCESS,
  payload: {
    realtime,
  },
});

export const fetchRealtimeFailure = ({ error }) => ({
  type: types.FETCH_REALTIME_FAILURE,
  payload: {
    error: error.message,
  },
});
