import * as types from "../types";

const initialState = {
  loading: false,
  area: null,
  requestData: null,
  daySummary: null,
  commutes: null,
  patches: null,
  replay: null,
  dailyStatus: null,
  totalTime: null,
  kms: null,
  // lastActive: null,
  error: null,
};

const daySummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DAY_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.payload.requestData,
      };
    case types.DAY_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        area: action.payload.area,
        totalTime: action.payload.totalTime,
        kms: action.payload.kms,
        // lastActive: action.payload.lastActive,
        daySummary: action.payload.daySummary,
        commutes: action.payload.commutes,
        patches: action.payload.patches,
        replay: action.payload.replay,
        dailyStatus: action.payload.dailyStatus,
      };
    case types.DAY_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        requestData: null,
        daySummary: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default daySummaryReducer;
