import * as types from "../types";

export const fetchFuelCulpritRequest = ({ requestDevice }) => ({
  type: types.FETCH_FUEL_CULPRIT_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchFuelCulpritSuccess = ({ fuelCulprit }) => ({
  type: types.FETCH_FUEL_CULPRIT_SUCCESS,
  payload: {
    fuelCulprit,
  },
});

export const fetchFuelCulpritFailure = ({ error }) => ({
  type: types.FETCH_FUEL_CULPRIT_FAILURE,
  payload: {
    error: error.message,
  },
});
