import { makeStyles } from "@material-ui/core/styles";

export const loginStyles = makeStyles((theme) => ({
  login: {
    width: "100%",
    height: "100vh",
    background: "#ffffff",
  },
  left: {
    flex: "1",
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  submit: {
    border: "none",
    outline: "none",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    fontSize: "1em",
    width: "100%",
    transition: "0.1s ease-in",
    backgroundColor: "#651fff",
    color: "white",
    gap: "1em",
  },
}));
