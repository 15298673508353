import * as types from "../types";

const initialState = {
  loading: false,
  fuelCulprit: null,
  requestDevice: null,
  error: null,
};

export default function fetchFuelCulpritReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FUEL_CULPRIT_REQUEST:
      return {
        ...state,
        loading: true,
        requestDevice: action.payload.requestDevice,
      };

    case types.FETCH_FUEL_CULPRIT_SUCCESS:
      return {
        ...state,
        loading: false,
        fuelCulprit: action.payload.fuelCulprit,
      };

    case types.FETCH_FUEL_CULPRIT_FAILURE:
      return {
        ...state,
        loading: false,
        fuelCulprit: null,
        requestDevice: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
