import styled, { css } from "styled-components";

const InputBorder = css`
  border: 1px solid red;
`;

const InputContainerWrapper = ({ children, className, ...props }) => (
  <div className={className} {...props}>
    {children}
  </div>
);

const InputContainer = styled(InputContainerWrapper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) => props.background ?? "#ffffff"};
  border-radius: 4px;
  opacity: 1;
  margin: 1em 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "45px")};
  margin-bottom: ${(props) => (props.error ? "0" : "1em")};
  border: ${(props) =>
    props.border === "none" ? "none" : "1px solid #999999"};
  ${(props) => props.error && InputBorder}
  :focus-within {
    outline: none !important;
    border: 1px solid #4d4d4d;
  }
`;

const InputIconWrapper = ({ className, children, ...props }) => (
  <span className={className} {...props}>
    {children}
  </span>
);

const InputIcon = styled(InputIconWrapper)`
  padding: 0.5em;
  & > img {
    width: 22px;
  }
`;

const InputElementWrapper = ({ className, ...props }) => (
  <input className={className} {...props} />
);

const InputElement = styled(InputElementWrapper)`
  flex: 1;
  outline: none;
  border: none;
  background: none;
  font-size: 1em;
  color: #333333;
  height: 100%;
  width: ${(props) => props.width ?? "230px"};
  margin: ${(props) =>
    props.iconposition === "right" ? "0 0 0 1em" : "0 1em 0 0"};
`;

const InputErrorWrapper = ({ children, ...props }) => (
  <p {...props}>{children}</p>
);

const InputError = styled(InputErrorWrapper)`
  font-size: 0.7em;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors["primary"]};
`;

export { InputContainer, InputIcon, InputElement, InputError };
