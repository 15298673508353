import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const TableComponent = ({ ...props }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actual Image</TableCell>
            <TableCell>Reference Image 1 </TableCell>
            <TableCell>Reference Image 2</TableCell>
            <TableCell>Reference Image 3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.values.map((row) => (
            <TableRow key={row.actual}>
              <TableCell>
                {row.actual === null ? (
                  "No Image"
                ) : (
                  <a
                    href={row.actual}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#0000FF" }}
                  >
                    Actual Image
                  </a>
                )}
              </TableCell>
              <TableCell>
                {row.reference1 === null ? (
                  "No Image"
                ) : (
                  <a
                    href={row.reference1}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#0000FF" }}
                  >
                    Reference Image 1
                  </a>
                )}
              </TableCell>
              <TableCell>
                {row.reference2 === null ? (
                  "No Image"
                ) : (
                  <a
                    href={row.reference2}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#0000FF" }}
                  >
                    Reference Image 2
                  </a>
                )}
              </TableCell>
              <TableCell>
                {row.reference3 === null ? (
                  "No Image"
                ) : (
                  <a
                    href={row.reference3}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#0000FF" }}
                  >
                    Reference Image 3
                  </a>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
