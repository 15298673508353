import * as types from "../types";

export const daySummaryRequest = ({ requestData }) => {
  return {
    type: types.DAY_SUMMARY_REQUEST,
    payload: {
      requestData,
    },
  };
};

export const daySummarySuccess = ({
  daySummary,
  commutes,
  patches,
  replay,
  dailyStatus,
  area,
  totalTime,
  kms,
}) => {
  return {
    type: types.DAY_SUMMARY_SUCCESS,
    payload: {
      daySummary,
      commutes,
      patches,
      replay,
      dailyStatus,
      area,
      totalTime,
      kms,
    },
  };
};

export const daySummaryFailure = (error) => {
  return {
    type: types.DAY_SUMMARY_FAILURE,
    payload: {
      error: error.message,
    },
  };
};
