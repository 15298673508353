import * as types from "../types";

export const fetchAppRequest = ({ requestDevice }) => ({
  type: types.FETCH_APP_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchAppSuccess = ({ app }) => ({
  type: types.FETCH_APP_SUCCESS,
  payload: {
    app,
  },
});

export const fetchAppFailure = ({ error }) => ({
  type: types.FETCH_APP_FAILURE,
  payload: {
    error: error.message,
  },
});
