import React from "react";
import { fuelAppStyles } from "./fuelApp.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getTimeStampIST } from "../../utils";

const AppData = ({ fuelapp, appLoading }) => {
  const classes = fuelAppStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>Last Open App (IST):</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{getTimeStampIST(fuelapp.Last_open_App)}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>App Build:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{fuelapp.app_build}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>App Version:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{fuelapp.app_version}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>App Language:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{fuelapp.app_language}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Area Unit:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{fuelapp.area_unit}</h4>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Brand:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <h4>{fuelapp.brand}</h4>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelapp: state.appReducer.app,
  appLoading: state.appReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppData);
