import React from "react";
import {
  InputContainer,
  InputIcon,
  InputElement,
  InputError,
} from "./Input.styled";

const Input = ({ icon, iconPosition, error, iconClick, type, ...props }) => {
  return (
    <>
      <InputContainer>
        {icon && iconPosition === "left" && (
          <InputIcon>
            <img
              src={icon ? icon : ""}
              alt="icon"
              onClick={iconClick ? iconClick : undefined}
            />
          </InputIcon>
        )}
        <InputElement
          type={type}
          spellCheck="false"
          iconposition={iconPosition}
          {...props}
        />

        {icon && iconPosition === "right" && (
          <InputIcon>
            <img
              src={icon ? icon : ""}
              alt="icon"
              onClick={iconClick ? iconClick : undefined}
              style={{ cursor: `${iconClick ? "pointer" : "normal"}` }}
            />
          </InputIcon>
        )}
      </InputContainer>
      {error && <InputError>{error}</InputError>}
    </>
  );
};

Input.defaultProps = {
  iconPosition: "left",
  type: "text",
};

export default Input;
