import React from "react";
import { fuelsummaryStyles } from "./fuelSummary.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const FuelSummary = ({ fuelsummary, fuelSummaryLoading, ...props }) => {
  const classes = fuelsummaryStyles();
  return (
    <div>
      <div>
        <div className={classes.text}>
          <p>Fuel Refill Delta:</p>
          {fuelSummaryLoading ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            <>
              {!fuelsummary ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                <span>{fuelsummary.fuel_refill_delta[0]}</span>
              )}
            </>
          )}
        </div>
        <div className={classes.text}>
          <p>Alert enable register:</p>
          {fuelSummaryLoading ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            <>
              {!fuelsummary ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                <span>{fuelsummary.alert_enable_register[0]}</span>
              )}
            </>
          )}
        </div>
        <div className={classes.text}>
          <p>Obd method:</p>
          {fuelSummaryLoading ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            <>
              {!fuelsummary ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                <span>{fuelsummary.obd_method[0]}</span>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <div className={classes.text} style={{ margin: "0.5em 0" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Fuel coef a</TableCell>
                  <TableCell>Fuel coef b</TableCell>
                  <TableCell>Fuel coef c</TableCell>
                  <TableCell>Fuel coef d</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={40} />
                        ) : (
                          <h5>{fuelsummary.fuel_coef_a[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={40} />
                        ) : (
                          <h5>{fuelsummary.fuel_coef_b[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={40} />
                        ) : (
                          <h5>{fuelsummary.fuel_coef_c[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={40} />
                        ) : (
                          <h5>{fuelsummary.fuel_coef_d[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={classes.text} style={{ margin: "0.5em 0" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Temp coef c</TableCell>
                  <TableCell>Temp coef b</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={100} />
                        ) : (
                          <h5>{fuelsummary.temp_coef_c[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {fuelSummaryLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : (
                      <>
                        {!fuelsummary ? (
                          <Skeleton animation="wave" width={100} />
                        ) : (
                          <h5>{fuelsummary.temp_coef_b[0]}</h5>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelsummary: state.fuelSummaryReducer.fuelSummary,
  fuelSummaryLoading: state.fuelSummaryReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelSummary);
