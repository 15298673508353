import React from "react";
import { fuelmodelStyles } from "./fuelModel.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

const FuelModel = ({ fuelmodel, modelLoading }) => {
  const classes = fuelmodelStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>Tractor Model:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.tractor_model[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Fuel Tank Capacity:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.fuel_tank_capacity[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Fuel Coef a:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_fuel_coef_a[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Fuel Coef b:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_fuel_coef_b[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Fuel Coef c:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_fuel_coef_c[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Fuel Coef d:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_fuel_coef_d[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Temp Coef c:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_temp_coef_c[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model Temp Coef b:</p>
        {modelLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelmodel ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{fuelmodel.model_temp_coef_b[0]}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelmodel: state.fuelModelReducer.fuelModel,
  modelLoading: state.fuelModelReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelModel);
