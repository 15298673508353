import React from "react";
import { RealtimeStyles } from "./realtime.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getData } from "../../utils";

const Dashboard = ({ current, currentLoading, ...props }) => {
  const classes = RealtimeStyles();

  return (
    <div>
      <h1>Realtime Data (10 minutes)</h1>
      <hr></hr>
      <div className={classes.text}>
        <p>Total Packets:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.total_packets[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Trip on Packets:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.trip_on_packets[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>RTPP:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.rtpp[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Lock Percentage:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.loc_per[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Last ADC Value:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.last_adc_val[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Average VBAT:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.avg_vbat[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Average Fuel liter:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData((current.avg_fuel_lit[0] / 100).toFixed(2))}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Average Speed:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <>
            {!current ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <span>{getData(current.avg_speed[0])}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current: state.realtimeReducer.realtime,
  currentLoading: state.realtimeReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
