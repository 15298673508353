import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchGsmGpsFailure,
  fetchGsmGpsSuccess,
} from "../actions/fetchGsmGpsAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchGsmGpsSaga(action) {
  try {
    yield put(clearErrors());
    let gsmGps = yield call(_api.deviceGsmGps, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("gsmgps ", gsmGps);
    yield put(fetchGsmGpsSuccess({ gsmGps }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchGsmGpsFailure({ error: e }));
  }
}
export default function* fetchGsmGpsSaga() {
  yield takeLatest(types.FETCH_GSM_GPS_REQUEST, asyncFetchGsmGpsSaga);
}
