import { takeLatest, call, put } from "redux-saga/effects";
import { simStatusFailure, simStatusSuccess } from "../actions/simStatusAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncSimStatusSaga(action) {
  try {
    yield put(clearErrors());
    let sim = yield call(_api.deviceSim, {
      requestDevice: action.payload.requestDevice,
    });
    yield put(simStatusSuccess({ sim }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(simStatusFailure({ error: e }));
  }
}
export default function* simStatusSaga() {
  yield takeLatest(types.SIM_STATUS_REQUEST, asyncSimStatusSaga);
}
