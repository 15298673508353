import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchInstAttemptsFailure,
  fetchInstAttemptsSuccess,
} from "../actions/fetchInstAttemptsAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchInstAttemptsSaga(action) {
  try {
    yield put(clearErrors());
    let instAttempts = yield call(_api.deviceAttempts, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("realtime ", realtime);
    yield put(fetchInstAttemptsSuccess({ instAttempts }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchInstAttemptsFailure({ error: e }));
  }
}
export default function* fetchInstAttemptsSaga() {
  yield takeLatest(
    types.FETCH_INST_ATTEMPTS_REQUEST,
    asyncFetchInstAttemptsSaga
  );
}
