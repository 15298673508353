import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

const AccordionComponent = ({ ...props }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{props.header}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.value}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
