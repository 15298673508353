import { useEffect, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Flex } from "../../utility";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import Person from "../../assets/icons/person.svg";
import Lock from "../../assets/icons/lock.svg";
import image from "../../assets/img/home-image@2x.jpg";
import Input from "../../elements/Input";

import { useLocation, Redirect } from "react-router-dom";
import { loginRequest } from "../../store/actions/loginAction";

import { loginStyles } from "./login.styles";

const Login = ({ isLoggedIn, loginMessage, loginLoading, ...props }) => {
  const { state } = useLocation();
  const [error, setError] = useState(null);

  const classes = loginStyles();

  useEffect(() => {
    if (!loginLoading && !isLoggedIn) {
      setError(loginMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, loginLoading]);

  if (isLoggedIn) {
    return <Redirect to={state?.from ?? "/"} />;
  }

  return (
    <Flex className={classes.login}>
      <Formik
        initialValues={{
          email: localStorage.getItem("_u") ?? "",
          password: "",
          remember: Boolean(localStorage.getItem("_r")) ?? false,
        }}
        validate={(values) => {
          const errors = {};
          if (values.email === "") {
            errors.email = "*Email is Required!";
          } else if (values.password === "") {
            errors.password = "*Password is Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setError(null);
          if (values.remember) {
            localStorage.setItem("_u", values.email);
            localStorage.setItem("_r", true);
          } else {
            localStorage.removeItem("_u");
            localStorage.removeItem("_r");
          }
          props.login({ email: values.email, password: values.password });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange }) => (
          <>
            <Flex flex={1} className={classes.left}>
              <img src={image} alt="" width="100%" height="100%" />
            </Flex>
            <Flex align="center" justify="center" flex={1}>
              <Flex direction="column" align="flex-start" gap="1.6em">
                <Flex direction="column" align="flex-start" justify="center">
                  <h1>Welcome to Internal Debug Portal</h1>
                  <p>Please Login Using Your AMK Credentials</p>
                </Flex>
                <Form>
                  <Flex direction="column" align="flex-start" gap="1em">
                    <h1>Log In</h1>
                    <Flex direction="column">
                      <Input
                        type="text"
                        icon={Person}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        error={touched.email && errors.email}
                      />
                      <Input
                        type="password"
                        icon={Lock}
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                        placeholder="Password"
                        autoComplete="on"
                        error={touched.password && errors.password}
                      />
                      {error && (
                        <Flex
                          align="center"
                          justify="flex-start"
                          style={{ width: "100%" }}
                        >
                          <p className="loginError">*{error}</p>
                        </Flex>
                      )}
                      <Flex
                        align="center"
                        justify="space-between"
                        flex={1}
                        style={{ width: "100%" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.remember}
                              onChange={handleChange}
                              name="remember"
                            />
                          }
                          label="Remember me"
                        />
                      </Flex>
                    </Flex>
                    <button
                      className={classes.submit}
                      type="submit"
                      disabled={loginLoading}
                    >
                      {loginLoading ? (
                        <>
                          <span>Logging In</span>

                          <CircularProgress
                            size="2em"
                            style={{ color: "white" }}
                          />
                        </>
                      ) : (
                        <span>Login</span>
                      )}
                    </button>
                  </Flex>
                </Form>
              </Flex>
            </Flex>
          </>
        )}
      </Formik>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  loginLoading: state.loginReducer.loading,
  isLoggedIn: state.loginReducer.isLoggedIn,
  loginMessage: state.loginReducer.message,
});

const mapDispatchToProps = (dispatch) => ({
  login: ({ email, password }) => {
    dispatch(loginRequest({ email, password }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
