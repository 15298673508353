// ERROR TYPES
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// LOGIN REQUEST TYPES
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

// LOGOUT REQUEST TYPES
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const LOGOUT_REQUEST_FAILURE = "LOGOUT_REQUEST_FAILURE";

//FUEL DATA
export const FETCH_FUEL_REQUEST = "FETCH_FUEL_REQUEST";
export const FETCH_FUEL_SUCCESS = "FETCH_FUEL_SUCCESS";
export const FETCH_FUEL_FAILURE = "FETCH_FUEL_FAILURE";

//REALTIME
export const FETCH_REALTIME_REQUEST = "FETCH_REALTIME_REQUEST";
export const FETCH_REALTIME_SUCCESS = "FETCH_REALTIME_SUCCESS";
export const FETCH_REALTIME_FAILURE = "FETCH_REALTIME_FAILURE";

//SUMMARY
export const FETCH_SUMMARY_REQUEST = "FETCH_SUMMARY_REQUEST";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAILURE = "FETCH_SUMMARY_FAILURE";

//FUEL SUMMARY
export const FETCH_FUEL_SUMMARY_REQUEST = "FETCH_FUEL_SUMMARY_REQUEST";
export const FETCH_FUEL_SUMMARY_SUCCESS = "FETCH_FUEL_SUMMARY_SUCCESS";
export const FETCH_FUEL_SUMMARY_FAILURE = "FETCH_FUEL_SUMMARY_FAILURE";

//FUEL MODEL
export const FETCH_FUEL_MODEL_REQUEST = "FETCH_FUEL_MODEL_REQUEST";
export const FETCH_FUEL_MODEL_SUCCESS = "FETCH_FUEL_MODEL_SUCCESS";
export const FETCH_FUEL_MODEL_FAILURE = "FETCH_FUEL_MODEL_FAILURE";

//FUEL CULPRIT
export const FETCH_FUEL_CULPRIT_REQUEST = "FETCH_FUEL_CULPRIT_REQUEST";
export const FETCH_FUEL_CULPRIT_SUCCESS = "FETCH_FUEL_CULPRIT_SUCCESS";
export const FETCH_FUEL_CULPRIT_FAILURE = "FETCH_FUEL_CULPRIT_FAILURE";

//TICKET
export const FETCH_TICKET_REQUEST = "FETCH_TICKET_REQUEST";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const FETCH_TICKET_FAILURE = "FETCH_TICKET_FAILURE";

//APP
export const FETCH_APP_REQUEST = "FETCH_APP_REQUEST";
export const FETCH_APP_SUCCESS = "FETCH_APP_SUCCESS";
export const FETCH_APP_FAILURE = "FETCH_APP_FAILURE";

//INST_ATTEMPTS
export const FETCH_INST_ATTEMPTS_REQUEST = "FETCH_INST_ATTEMPTS_REQUEST";
export const FETCH_INST_ATTEMPTS_SUCCESS = "FETCH_INST_ATTEMPTS_SUCCESS";
export const FETCH_INST_ATTEMPTS_FAILURE = "FETCH_INST_ATTEMPTS_FAILURE";

//GSMGPS
export const FETCH_GSM_GPS_REQUEST = "FETCH_GSM_GPS_REQUEST";
export const FETCH_GSM_GPS_SUCCESS = "FETCH_GSM_GPS_SUCCESS";
export const FETCH_GSM_GPS_FAILURE = "FETCH_GSM_GPS_FAILURE";

//INST IMG
export const FETCH_INST_IMG_REQUEST = "FETCH_INST_IMG_REQUEST";
export const FETCH_INST_IMG_SUCCESS = "FETCH_INST_IMG_SUCCESS";
export const FETCH_INST_IMG_FAILURE = "FETCH_INST_IMG_FAILURE";

//FIREBASE  DATA
export const FETCH_FIREBASE_REQUEST = "FETCH_FIREBASE_REQUEST";
export const FETCH_FIREBASE_SUCCESS = "FETCH_FIREBASE_SUCCESS";
export const FETCH_FIREBASE_FAILURE = "FETCH_FIREBASE_FAILURE";

//THRESHOLD VALUE
export const FETCH_THRESHOLD_REQUEST = "FETCH_THRESHOLD_REQUEST";
export const FETCH_THRESHOLD_SUCCESS = "FETCH_THRESHOLD_SUCCESS";
export const FETCH_THRESHOLD_FAILURE = "FETCH_THRESHOLD_FAILURE";

// FETCH DAY SUMMARY
export const DAY_SUMMARY_REQUEST = "DAY_SUMMARY_REQUEST";
export const DAY_SUMMARY_SUCCESS = "DAY_SUMMARY_SUCCESS";
export const DAY_SUMMARY_FAILURE = "DAY_SUMMARY_FAILURE";

// FUEL TIKCET_METADATA
export const FUEL_TICKET_METADATA_REQUEST = "FUEL_TICKET_METADATA_REQUEST";
export const FUEL_TICKET_METADATA_SUCCESS = "FUEL_TICKET_METADATA_SUCCESS";
export const FUEL_TICKET_METADATA_FAILURE = "FUEL_TICKET_METADATA_FAILURE";

// FUEl TICKET
export const FUEL_TICKET_REQUEST = "FUEL_TICKET_REQUEST";
export const FUEL_TICKET_SUCCESS = "FUEL_TICKET_SUCCESS";
export const FUEL_TICKET_FAILURE = "FUEL_TICKET_FAILURE";
export const FUEL_TICKET_RESET = "FUEL_TICKET_RESET";

// SIM STATUS
export const SIM_STATUS_REQUEST = "SIM_STATUS_REQUEST";
export const SIM_STATUS_SUCCESS = "SIM_STATUS_SUCCESS";
export const SIM_STATUS_FAILURE = "SIM_STATUS_FAILURE";

// SIM STATUS
export const REPLACEMENT_DATA_REQUEST = "REPLACEMENT_DATA_REQUEST";
export const REPLACEMENT_DATA_SUCCESS = "REPLACEMENT_DATA_SUCCESS";
export const REPLACEMENT_DATA_FAILURE = "REPLACEMENT_DATA_FAILURE";