import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchTicketFailure,
  fetchTicketSuccess,
} from "../actions/fetchTicketAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchTicketSaga(action) {
  try {
    yield put(clearErrors());
    let ticket = yield call(_api.deviceTicket, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("realtime ", realtime);
    yield put(fetchTicketSuccess({ ticket }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchTicketFailure({ error: e }));
  }
}
export default function* fetchRealtimeSaga() {
  yield takeLatest(types.FETCH_TICKET_REQUEST, asyncFetchTicketSaga);
}
