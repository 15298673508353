import * as types from "../types";

export const loginRequest = ({ email, password }) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: { email, password },
  };
};

export const loginRequestSuccess = ({ status, message }) => {
  return {
    type: types.LOGIN_REQUEST_SUCCESS,
    status,
    message,
  };
};

export const loginRequestFailure = ({ status, message }) => {
  return {
    type: types.LOGIN_REQUEST_FAILURE,
    status,
    message,
  };
};
