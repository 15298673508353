import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchFuelSummaryFailure,
  fetchFuelSummarySuccess,
} from "../actions/fetchFuelSummaryAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchFuelSummarySaga(action) {
  try {
    yield put(clearErrors());
    let fuelSummary = yield call(_api.deviceFuelSummary, {
      requestDevice: action.payload.requestDevice,
    });
    // console.log("fuelSummary ", fuelSummary);
    yield put(fetchFuelSummarySuccess({ fuelSummary }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchFuelSummaryFailure({ error: e }));
  }
}
export default function* fetchFuelSummarySaga() {
  yield takeLatest(types.FETCH_FUEL_SUMMARY_REQUEST, asyncFetchFuelSummarySaga);
}
