import * as types from "../types";

export const simStatusRequest = ({ requestDevice }) => ({
  type: types.SIM_STATUS_REQUEST,
  payload: {
    requestDevice,
  },
});

export const simStatusSuccess = ({ sim }) => ({
  type: types.SIM_STATUS_SUCCESS,
  payload: {
    sim,
  },
});

export const simStatusFailure = ({ error }) => ({
  type: types.SIM_STATUS_FAILURE,
  payload: {
    error: error.message,
  },
});
