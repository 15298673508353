import * as types from "../types";

export const fetchFuelTicketRequest = ({ requestTicket }) => ({
  type: types.FUEL_TICKET_METADATA_REQUEST,
  payload: {
    requestTicket,
  },
});

export const fetchFuelTicketSuccess = ({ resFuelTicket }) => ({
  type: types.FUEL_TICKET_METADATA_SUCCESS,
  payload: {
    resFuelTicket,
  },
});

export const fetchFuelTicketFailure = ({ error }) => ({
  type: types.FUEL_TICKET_METADATA_FAILURE,
  payload: {
    error: error.message,
  },
});
