import React from "react";
import { fuelCulpritStyles } from "./fuelCulprit.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { culpritValue, getData } from "../../utils";

const FuelCulprit = ({ fuelCulprit, fuelCulpritLoading }) => {
  const classes = fuelCulpritStyles();
  return (
    <div>
      <div className={classes.text}>
        <p>Device Id:</p>
        {fuelCulpritLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelCulprit ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getData(fuelCulprit.device_id[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Is Fuel Culprit:</p>
        {fuelCulpritLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelCulprit ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{culpritValue(fuelCulprit.Is_Fuel_culprit_[0])}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fuelCulprit: state.fuelCulpritReducer.fuelCulprit,
  fuelCulpritLoading: state.fuelCulpritReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelCulprit);
