import * as types from "../types";

export const fetchSummaryRequest = ({ requestDevice }) => ({
  type: types.FETCH_SUMMARY_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchSummarySuccess = ({ summary }) => ({
  type: types.FETCH_SUMMARY_SUCCESS,
  payload: {
    summary,
  },
});

export const fetchSummaryFailure = ({ error }) => ({
  type: types.FETCH_SUMMARY_FAILURE,
  payload: {
    error: error.message,
  },
});
