import { takeLatest, call, put } from "redux-saga/effects";
import { fetchFuelTicketSuccess, fetchFuelTicketFailure } from "../actions/fetchfuelTicketAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchFuelTicketSaga(action) {
  try {
    yield put(clearErrors());
    let resFuelTicket = yield call(_api.getFuelTicket, {
      requestTicket: action.payload.requestTicket,
    });
    yield put(fetchFuelTicketSuccess({ resFuelTicket }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchFuelTicketFailure({ error: e }));
  }
}
export default function* fetchFuelTicketSaga() {
  yield takeLatest(types.FUEL_TICKET_METADATA_REQUEST, asyncFetchFuelTicketSaga);
}
