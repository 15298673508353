import * as types from "../types";

const initialState = {
  loading: false,
  instImage: null,
  requestDevice: null,
  error: null,
};

export default function fetchInstImageReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_INST_IMG_REQUEST:
      return {
        ...state,
        loading: true,
        requestDevice: action.payload.requestDevice,
      };

    case types.FETCH_INST_IMG_SUCCESS:
      return {
        ...state,
        loading: false,
        instImage: action.payload.instImage,
      };

    case types.FETCH_INST_IMG_FAILURE:
      return {
        ...state,
        loading: false,
        instImage: null,
        requestDevice: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
