import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import CircularProgress from "@material-ui/core/CircularProgress";

// Resolves charts dependancy
charts(FusionCharts);

function FuelADC({
  fuelLoading,
  fueldata,
  voltageThresh,
  summary,
  summaryLoading,
  startTime,
  endTime
}) {
  const [series, setSeries] = useState([]);
  const [fuelLow, setFuelLow] = useState(350);
  const [fuelHigh, setFuelHigh] = useState(7500);

  useEffect(() => {
    if (fueldata && fueldata.message === undefined) {
      let timeStampData = Object.values(fueldata.time_stamp);
      let fuelADCData = Object.values(fueldata.fuel_adc);

      let threshLowValue = Object.values(voltageThresh.fuel_low);
      let threshHighValue = Object.values(voltageThresh.fuel_high);

      if (threshLowValue.length > 0 && threshHighValue.length > 0) {
        setFuelLow(voltageThresh.fuel_low[0]);
        setFuelHigh(voltageThresh.fuel_high[0]);
      } else {
        if (!summaryLoading) {
          if (summary.hw_batch[0] === 49 || summary.hw_batch[0] === 52) {
            setFuelLow(100);
            setFuelHigh(2100);
          } else {
            setFuelLow(350);
            setFuelHigh(7500);
          }
        }
      }

      let data = [];
      let count = 0;
      for (let i = startTime*60; i < endTime*60; i++) {
        if (fuelADCData[i] !== -1) {
          count++;
        }
      }

      let size;
      if (count < 100) {
        size = "6";
      } else {
        size = "3";
      }
      for (let i = startTime*60; i < endTime*60; i++) {
        if (fuelADCData[i] === -1) {
          data.push({
            label: timeStampData[i],
          });
        } else if (fuelADCData[i] < 1) {
          data.push({
            label: timeStampData[i],
            value: fuelADCData[i],
            drawAnchors: "1",
            anchorRadius: "1",
            anchorBorderThickness: "3",
            anchorBorderColor: "#FF0000",
            anchorSides: "3",
            anchorBgColor: "#d3f7ff",
          });
        } else {
          data.push({
            label: timeStampData[i],
            value: fuelADCData[i],
            drawAnchors: "1",
            anchorRadius: "1",
            // anchorBorderThickness: "0.5",
            anchorBorderThickness: size,
            anchorBorderColor: "#FFCC00",
            anchorSides: "1",
            anchorBgColor: "#FFCC00",
          });
        }
      }

      setSeries(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fueldata, voltageThresh, summary, summaryLoading,startTime,endTime]);

  const chartConfigs = {
    chart: {
      xAxisName: "TimeStamp (IST)",
      yAxisName: "Fuel ADC plot",
      showValues: "0",
      showBorder: "0",
      showCanvasBorder: "0",
      drawAnchors: "0",
      xAxisNameFontSize: "20",
      xAxisNameFontColor: "#651fff",
      yAxisNameFontSize: "20",
      yAxisNameFontColor: "#651fff",
      labelStep: "60",
      baseFontSize: "11",
      plottooltext:
        "<div>TimeStamp : <b>$label</b></div><hr class='demo'>Fuel ADC : <b>$dataValue</b>",
      theme: "fusion",
    },
    data: series,
    trendlines: [
      {
        line: [
          {
            // startvalue: "13200",
            startvalue: fuelLow,
            endvalue: "",
            color: "#29C3BE",
            displayvalue: `${fuelLow}`,
            valueonright: "1",
            dashed: "1",
            thickness: "2",
          },
          {
            // startvalue: "13200",
            startvalue: fuelHigh,
            endvalue: "",
            color: "#651fff",
            displayvalue: `${fuelHigh}`,
            valueonright: "1",
            dashed: "1",
            thickness: "2",
          },
        ],
      },
    ],
  };

  function ChartData() {
    // let timeStammp = Object.values(fueldata.time_stamp);
    if (fuelLoading) {
      return (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      );
    } else if (
      fueldata &&
      fueldata.message === undefined &&
      Object.values(fueldata.time_stamp).length > 0
    ) {
      return (
        <ReactFusioncharts
          type="line"
          dataFormat="JSON"
          width="100%"
          height="600"
          dataSource={chartConfigs}
        />
      );
    } else {
      return (
        <h1 style={{ textAlign: "center", padding: "5em" }}>{fueldata?.message}</h1>
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {!fueldata ? (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      ) : (
        ChartData()
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fueldata: state.fuelReducer.fuel,
  fuelLoading: state.fuelReducer.loading,
  voltageThresh: state.thresholdReducer.thresholdValue,
  summary: state.summaryReducer.summary,
  summaryLoading: state.summaryReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelADC);
