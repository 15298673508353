import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchFuelFailure,
  fetchFuelSuccess,
} from "../actions/fetchfuelsAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchFuelSaga(action) {
  try {
    yield put(clearErrors());
    let fuel = yield call(_api.fuels, {
      requestData: action.payload.requestData,
    });
    // console.log("fuel ", fuel);
    yield put(fetchFuelSuccess({ fuel }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchFuelFailure({ error: e }));
  }
}
export default function* fetchFuelSaga() {
  yield takeLatest(types.FETCH_FUEL_REQUEST, asyncFetchFuelSaga);
}
