import React, { useState } from "react";
import CustomerChart from "../../fusionChart/customerChart";
import GsmChart from "../../fusionChart/gsmChart";
import DelayChart from "../../fusionChart/delayChart";
import DistanceChart from "../../fusionChart/distanceChart";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { fetchFuelRequest } from "../../store/actions/fetchfuelsAction";

const QualityChart = ({ ...props }) => {
  const [chart, setChart] = useState(1);

  const handleChart = (event) => {
    const chartType = event.target.value;
    setChart(chartType);
  };

  function changeChart() {
    if (chart === 2) {
      return <DelayChart />;
    } else if (chart === 3) {
      return <CustomerChart />;
    } else {
      return <DistanceChart />;
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
        }}
      >
        <Select
          id="demo-simple-select-outlined"
          defaultValue={1}
          value={chart}
          onChange={handleChart}
          style={{
            background: "#651fff ",
            height: "45px",
            width: "180px",
            borderRadius: "4px",
            color: "#FFFFFF",
            padding: "0 0.5em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MenuItem value={1}>Gsm Chart</MenuItem>
          <MenuItem value={2}>Delay Chart</MenuItem>
          <MenuItem value={3}>Customer Chart</MenuItem>
          <MenuItem value={4}>Distance Chart</MenuItem>
        </Select>
      </div>
      {chart === 1 ? <GsmChart /> : changeChart()}
    </>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  fuelSubmit: ({ requestData }) => dispatch(fetchFuelRequest({ requestData })),
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityChart);
