import * as types from "../types";

const initialState = {
  loading: false,
  gsmGps: null,
  requestDevice: null,
  error: null,
};

export default function fetchGsmGpsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GSM_GPS_REQUEST:
      return {
        ...state,
        loading: true,
        requestDevice: action.payload.requestDevice,
      };

    case types.FETCH_GSM_GPS_SUCCESS:
      return {
        ...state,
        loading: false,
        gsmGps: action.payload.gsmGps,
      };

    case types.FETCH_GSM_GPS_FAILURE:
      return {
        ...state,
        loading: false,
        gsmGps: null,
        requestDevice: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
