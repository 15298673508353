import * as types from "../types";

export const fetchFirebaseDataRequest = ({ requestDevice }) => ({
  type: types.FETCH_FIREBASE_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchFirebaseDataSuccess = ({ firebaseData }) => ({
  type: types.FETCH_FIREBASE_SUCCESS,
  payload: {
    firebaseData,
  },
});

export const fetchFirebaseDataFailure = ({ error }) => ({
  type: types.FETCH_FIREBASE_FAILURE,
  payload: {
    error: error.message,
  },
});
