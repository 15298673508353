export const API_ROUTES = {
  LOGIN: "/cms/tech/api/login/",
  FUELS: "/debug/get_trip_info/",
  REALTIME: "/dev//idp/realtime/",
  DEVICE_SUMMARY: "/dev/idp/summary/",
  DEVICE_FUEL_SUMMARY: "/dev/idp/fuelsummary/",
  MODEL: "/dev/idp/fuelmodel/",
  FUEL_CULPRIT: "/dev/idp/fuelculprit/",
  DEVICE_TICKET: "/dev/idp/ticket/",
  APP: "/debug/get_app_data/",
  INSTALLATION: "/dev/idp/inst_attempts/",
  GSM_GPS: "/dev/idp/gsmgps/",
  INSTALLATION_IMAGE: "/dev/idp/inst_img/",
  FIREBASE: "/cms/check_presence/",
  THRESHOLD: "/dev/idp/vbat_thresh/",
  CLOSE_TICKET:"/cms/close_ticket/",
  FUEL_TICKET:"/cms/get_fuel_ticket_metadata/",
  SIM:"/debug/get_sim_summary/",
  REPLACEMENT_DETAIL:'/debug/get_replacement_summary/'
};
