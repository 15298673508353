import * as types from "../types";

export const fetchTicketRequest = ({ requestDevice }) => ({
  type: types.FETCH_TICKET_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchTicketSuccess = ({ ticket }) => ({
  type: types.FETCH_TICKET_SUCCESS,
  payload: {
    ticket,
  },
});

export const fetchTicketFailure = ({ error }) => ({
  type: types.FETCH_TICKET_FAILURE,
  payload: {
    error: error.message,
  },
});
